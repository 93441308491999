
@mixin breakpoint($name) {
    @if $name == "$hidpi" {
        @media (-webkit-min-device-pixel-ratio: 1.5),
        (min--moz-device-pixel-ratio: 1.5),
        (-o-min-device-pixel-ratio: 1.5),
        (min-device-pixel-ratio: 1.5),
        (min-resolution: 1.5dppx) {
            @content;
        }
    }
    @else {
        // $value: map-get($breakpoints, $name); // If the key exists in the map
        $value : $name;
        @if $value != null { // Prints a media query based on the value
            @media (min-width: $value) {
                @content
            }
        }
    }
}

@mixin bp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
}