@mixin line-clamp($maxLines: 2, $lineHeight:1.5) {
  position: relative;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-height: $maxLines * $lineHeight * 1em;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $maxLines;
}


// // use    
// // .line-clamp-2 { @include line-clamp(2); }
// // .line-clamp-3 { @include line-clamp(3); }
// // .line-clamp-4 { @include line-clamp(4); }
// // .line-clamp-5 { @include line-clamp(5); }
