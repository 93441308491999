@include purple-launcher {
    .container {
        max-height: calc(100vh - 58px);
        overflow-y: overlay;
        padding-bottom: 0;
    }
    .wrap-contents,
    .max-width-container {
        max-width: unset;
    }

    .section-contents {
        padding: 20px 40px;
        min-height: unset;
    }

    .depth3-list-wrap,
    .depth4-tab,
    .tab-menu-container {
        margin-bottom: 12px;
    }

    .depth3-tab + .depth4-tab .depth4-list-wrap {
        margin-top: -12px;
    }
}
