$breakpointsInDropdown: (
    notPC: 'max-width: 959px'
);
$breakpoints: map-merge($breakpoints, $breakpointsInDropdown);

@mixin calcWidth($cols) {
    width: calc(100% / $cols);
}

// dropdown module
.ncui-dropdown-wrap {
    position: relative;

    .dropdown-btn-wrap {
        display: inline-block;

        &.border {
            .dropdown-btn {
                height: 44px;
                padding: 0 10px;
                border-color: var(--ncui-gray012);
                border-radius: 4px;
                border-style: solid;
                border-width: 1px;
            }
        }

        .dropdown-btn {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: auto;
            font-size: 14px;
            color: var(--ncui-black068);
            line-height: 1.5;
            text-align: left;
            white-space: nowrap;
            cursor: pointer;
            border: 0;
            background: transparent;
            @include bp(pc) {
                font-size: 15px;
            }

            &__text {
                font-weight: 400;
            }

            &__icon {
                margin-left: 5px;

                path {
                    fill: var(--ncui-black045);
                }
            }
        }
    }

    .dropdown-layer-wrap {
        display: none;
        flex-wrap: nowrap;
        position: absolute;
        left: 0;
        top: calc(100% + 15px);
        z-index: 10;
        width: auto;
        max-height: 216px;
        background-color: var(--ncui-bg);
        
        &.is-open {
            display: flex;
        }

        &.layer-wide {
            &.is-open {
                display: block;
            }

            .menu-wide {
                background-color: var(--ncui-gray004);
                border-left: 1px solid var(--ncui-gray012);
                border-bottom: 0;
            }

            .menu-list {
                display: flex;
            }
        }

        .menu-wrap,
        .menu-wide {
            width: auto;
            min-width: 100px;
            overflow-y: auto;
            border: 1px solid var(--ncui-gray012);
            border-left: 0;

            &.menu-1 {
                background-color: var(--ncui-gray004);
                border-left: 1px solid var(--ncui-gray012);
            }

            & > ul {
                margin: 0;
                padding: 0;

                & > li {
                    border-bottom: 1px solid var(--ncui-gray012);

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }

            a, button {
                display: flex;
                align-items: center;
                min-width: 105px;
                height: 42px;
                padding: 0 17px;
                font-size: 14px;
                line-height: 1.5;
                color: var(--ncui-black068);
                white-space: nowrap;
                text-decoration: none;
                transition: color .2s ease-in-out;
                @include bp(pc) {
                    font-size: 15px;
                }

                &.is-active {
                    color: var(--ncui-black093);
                    background-color: var(--ncui-bg);
                }

                &:hover {
                    color: var(--ncui-point);
                }
            }
        }
    }

    .dropdown-select-wrap {
        display: inline-flex;
        align-items: center;
        height: 28px;
        padding: 0 6px 0 8px;
        font-size: 14px;
        color: var(--ncui-black068);
        text-align: left;
        background: var(--ncui-gray004);
        border: 1px solid var(--ncui-gray008);
        border-radius: 4px;

        // PC/ingame/launcher
        body.pc &,
        body.launcher &,
        body.ingame & {
            display: none;
        }

        .dropdown-select {
            position: relative;
            z-index: 1;
            height: 100%;
            padding: 0;
            color: inherit;
            font-size: inherit;
            line-height: 1.3;
            background-color: transparent;
            outline: none;
            border: 0;
            border-radius: 0;
            appearance:none;
            -webkit-appearance: none;
            -moz-appearance:none;
        }

        .dropdown-select-icon {
            flex: none;
            margin: 1px 0 0 2px;
            fill: var(--ncui-black045);
        }
    }

    // fit-mode
    &.fit-mode {
        @include bp(notPC) {
            .dropdown-btn-wrap {
                display: block;

                .dropdown-btn {
                    width: 100%;
                }
            }

            .dropdown-layer-wrap {
                width: 100%;

                .menu-wrap {
                    @include calcWidth(var(--menu-cols));
                }
            }

            .dropdown-select-wrap {
                justify-content: space-between;
                width: 100%;

                .dropdown-select {
                    width: 100%;
                }
            }
        }
    }

    // 아랍어 대응
    html[dir=rtl] & {
        .dropdown-btn {
            text-align: right;

            &__icon {
                margin-left: 0;
                margin-right: 5px;
            }
        }

        .dropdown-layer-wrap {
            left: inherit;
            right: 0;

            &.layer-wide {
                .menu-wide {
                    border-right: 1px solid var(--ncui-gray012);
                }
            }

            .menu-wrap,
            .menu-wide {
                border-left: 1px solid var(--ncui-gray012);
                border-right: 0;

                &.menu-1 {
                    border-right: 1px solid var(--ncui-gray012);
                }
            }
        }

        .dropdown-select-wrap {
            padding: 0 8px 0 6px;
            text-align: right;
    
            .dropdown-select-icon {
                margin: 1px 2px 0 0;
            }
        }
    }
}

// Tablet/Mobile
body.tablet,
body.mobile,
html[data-platform="purpleApp"] {
    .ncui-dropdown-wrap {
        .only-pc {
            display: none;
        }
    }
}