.ncui-timezone {
  position: relative;
}

.ncui-timezone-btn {
  .btn-timezone {
    &.active {
      color: #fff;
      background-color: green;
    }
  }
}

// LAYER
.ncui-timezone-layer-wrap {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9100010;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  @supports (height: 100dvh) { // Dynamic Viewport Height
    height: 100dvh;
  }

  &.active {
    display: block;
  }
  
  .ncui-timezone-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    min-height: 100vh;
    padding: 30px 0;
    @supports (min-height: 100dvh) { // Dynamic Viewport Height
      min-height: 100dvh;
    }
  }

  .ncui-timezone-dim {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .ncui-timezone-layer-inner {
    position: relative;
    z-index: 5;
    width: 320px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4B96CC;
    border-radius: 8px;
    @include bp(pc) {
      width: 440px;
    }
  }

  // 맨 마지막 dropdown이 잘리지 않도록 overflow: hidden 사용하지 않음
  .ncui-timezone-content-wrap {
    padding: 30px;
  }

  .ncui-timezone-content {
    background: transparent;
  }

  .ncui-timezone-btn-wrap {
    display: flex;
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    button {
      width: 50%;
      height: 48px;
      font-size: 15px;
      background: transparent;
      border: 0;
      cursor: pointer;
    }

    .ncui-timezone-btn-reset {
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.6);
    }

    .ncui-timezone-btn-close {
      color: #fff;
      background-color: #4B97CB;
    }
  }
}

// CONTNET
.ncui-timezone-content {
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4B96CC;

  .ncui-timezone-item {
    width: 100%;
    height: auto;
    margin-top: 16px;
    padding: 16px 20px;
    background: rgba(75, 150, 204, 0.2);
    border-radius: 4px;
    @include bp(pc) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      padding: 0 20px;
    }

    &:first-child {
      margin-top: 0;
    }

    .timezone-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      @include bp(pc) {
        display: block;
        margin-bottom: 0;
      }
    }

    .timezone-name {
      display: inline-block;
      margin: 0;
      font-size: 22px;
      color: rgba(255, 255, 255, 0.83);
      line-height: 24px;
      vertical-align: middle;
    }

    .timezone-diff {
      display: inline-block;
      padding: 2px 6px;
      margin-left: 6px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 100px;
      vertical-align: middle;
    }

    .timezone-date {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.38);
      @include bp(pc) {
        margin-top: 4px;
      }
    }

    .timezone-time {
      position: relative;

      .timezone-current {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 0 13px 0 16px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        @include bp(pc) {
          width: 180px;
        }

        &::after {
          display: block;
          content: '';
          position: absolute;
          right: 13px;
          top: 14px;
          width: 16px;
          height: 16px;
          background-color: #D8D8D8;
          @include svg-mask('../../img/md-timezone/icon-arrow.svg');
        }

        &.no-dropdown {
          justify-content: center;

          &::after {
            display: none;
          }
        }

        .icon-night,
        .icon-morning {
          display: block;
          margin-right: 10px;
          text-indent: -9999px;
          background-color: rgba(255, 255, 255, 0.83);
        }

        .icon-night {
          width: 14px;
          height: 14px;
          @include svg-mask('../../img/md-timezone/icon-night.svg');
        }

        .icon-morning {
          width: 16px;
          height: 16px;
          @include svg-mask('../../img/md-timezone/icon-morning.svg');
        }
      }

      .dropdown-btn-wrap {
        display: block;
        @include bp(pc) {
          display: inline-block;
        }

        &.is-active {
          $activeColor: rgba(255, 255, 255, 1);
  
          .timezone-current {
            color: $activeColor;
  
            &::after {
              background-color: $activeColor;
              transform: rotate(180deg);
            }
  
            .icon-night,
            .icon-morning {
              background-color: $activeColor;
            }
          }
        }
      }

      .ncui-dropdown-wrap {
        .dropdown-layer-wrap {
          top: 100%;
          width: 100%;
          max-height: 130px;
          border-radius: 0 0 4px 4px;

          .menu-wrap {
            width: 100%;
          }
        }
      }
    }
  }
}