.ncuim-backdrop {
    * {
        box-sizing: border-box;

        button {
            display: inline-block;
            padding: 0;
            border: none;
            outline: none;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
