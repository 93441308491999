// 스크롤 디자인
@mixin custom-scroll {
    // firefox 스크롤 대응
    scrollbar-color: var(--ncui-gray010) var(--ncui-gray010);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--ncui-gray010);
        mix-blend-mode: normal;
        background-clip: padding-box;
        border: 7px solid transparent;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
}
