// 택스트
$text1 : rgba(0, 0, 0, 0.93);
$text2 : rgba(255, 255, 255, 0.83);
$text3 : rgba(0, 0, 0, 0.68);
$text4 : rgba(255, 255, 255, 0.6);
$text5 : rgba(255, 255, 255, 0.38);


// 배경
$bg1: rgba(255, 255, 255, 0.95);
$bg2: #292929;
$bg3: #d7d7d7;
$bg4 : rgba(255, 255, 255, 0.38);
// $bg5: #fff;
$bg6: rgba(255, 255, 255, 0.08);


// 보더
$border1:  rgba(34, 34, 34, 0.1);
$border2:  rgba(255, 255, 255, 0.12);
$border3:  transparent;
$border4:   rgba(255, 255, 255, 0.2);

// 버튼
$btn1: #171717;
$btn2: rgba(255, 255, 255, 0.04);
$btn3: #fff;
$btn4: #292929;

// 스크롤바
$scroll-bar-thumb: rgba(#838499, 0.30);

$svgDarkMode: "svg[data-dark-mode='dark']";
$img-icon-check-off: "dark-icon-check-off";
$img-icon-check-on-diabled: "dark-icon-check-on-disabled";
$img-icon-check-on: "dark-icon-check-on";
