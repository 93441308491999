// my guild header (profile)
// ===============================

[class^="btn-guild-"],
[class*=" btn-guild-"] {
  min-width: 64px;
  height: calc(64px/2);
  padding: 0 13px;
  margin: 0 3px;
  font-size: 14px;
  text-align: center;
  border-radius: calc(64px/4);

  @include bp(pc) {
    min-width: 90px;
    height: 34px;
    margin: 0 4px;
    padding-bottom: 2px;
    font-size: 13px;
    line-height: 32px;
    border-radius: 34px;
  }
}

// 속이 빈 기본 타입1
.btn-guild-type1 {
  display: inline-block;
  color: var(--ncui-black068);
  border: 1px solid var(--ncui-gray012);
  background-color: transparent;
}

// 속이 찬 기본 타입2
.btn-guild-type2 {
  color: #fff;
  border: 0 none;
  background-color: var(--ncui-point);

  @include bp(pc) {
    padding-bottom: 2px;
  }
}

.guild-bar {
  content: '';
  display: inline-block;
  height: 12px;
  margin-left: 4px;
  padding-right: 8px;
  border-left: 1px solid var(--ncui-gray012);
  
  @include bp(pc) {
    margin-left: 2px;
    padding-right: 6px;
  }
}

.my-guild-header {
  height: 272px;
  color: var(--ncui-point);
  text-align: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
  font-size: 0;
  &:before{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  @include bp(pc) {
    height: auto;
    padding: 10px 0 45px;
    border: 0 none;
    background: none;
    &:before{
      display: none;
    }
  }

  .header-inner{
    display: inline-block;
    vertical-align: middle;
  }

  .profile {
    width: 120px;
    height: 120px;
    margin: auto;
    background: url("/img/official/service/lineagew/profile.png") no-repeat center top;
    background-size: auto 100%;

    @include bp(pc) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .info {
    margin-top: 14px;

    @include bp(pc) {
      display: inline-block;
      margin: 0 0 0 20px;
      text-align: left;
      vertical-align: middle;
    }

    .server {
      color: var(--ncui-ui-white);
      font-size: 17px;

      @include bp(pc) {
        color: var(--ncui-black093);
        font-size: 16px;
      }
    }

    .name {
      color: var(--ncui-ui-white);
      font-size: 17px;

      &:before {
        @extend .guild-bar;
        margin: 0 8px;
        padding: 0;
        border-color: var(--ncui-gray012);
      }

      @include bp(pc) {
        color: var(--ncui-black093);
        font-size: 16px;
      }
    }

    .person {
      color: var(--ncui-black045); //check check 다른 부분

      &:before {
        @extend .guild-bar;
      }
    }

    .btn-wrap {
      margin-top: 15px;

      @include bp(pc) {
        margin-left: -4px;
      }
    }

    .btn {
      @extend .btn-guild-type1;
      vertical-align: top;

      @include bp(pc) {
        padding-left: 16px;
        padding-right: 16px;
      }

      &-guild-member-event {
        @extend .btn-guild-type2;
      }

      &-guild-admin {
        position: relative;

        &.admin-new {
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: -6px;
            right: -4px;
            width: 20px;
            height: 18px;
            background: url("/img/official/my/guild/ic_admin_new.png") no-repeat;
            background-size: 100%;
          }
        }
      }
    }

    a.btn {
      line-height: calc(64px/2) -2px;

      @include bp(pc) {
        line-height: 33px;
      }
    }

    a.btn-guild-mtalk {
      min-width: 150px;
      height: 40px;
      padding: 0 22px;
      line-height: 38px;
      text-align: center;
      border-radius: 20px;

      &:before {
        content: "";
        display: inline-block;
        width: 25px;
        height: 23px;
        margin-top: 9px;
        margin-right: 4px;
        background: url("/img/official/my/guild/ic_mtalk_v2.png") no-repeat;
        background-size: cover;
        vertical-align: top;
      }
    }

    @include bp(pc) {
      .btn-wrap {
        text-align: left;
      }
    }
  }
}