* {
  box-sizing: border-box;
}

input, textarea, button {
  outline: 0;
}

[class*=" is-show"] {
  display: block;
}

html {
  height: 100%;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
  padding: 0;
}

button {
  display: inline-block;
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

