
.ncuim-backdrop {
  &.ncuim-top-start,
  &.ncuim-top,
  &.ncuim-top-end {
    .ncuim-toast-show {
      transform: translate3d(0, 16px, 0);
      animation: ncuim-toast-show-top 0.3s;
    }
    .ncuim-toast-hide {
      animation: ncuim-toast-hide-top 0.3s;
    }
  }

  &.ncuim-center-start,
  &.ncuim-center,
  &.ncuim-center-end {
    .ncuim-toast-show {
      animation: ncuim-toast-show-center 0.1s;
    }
    .ncuim-toast-hide {
      animation: ncuim-toast-hide-center 0.1s;
    }
  }

  &.ncuim-bottom-start,
  &.ncuim-bottom,
  &.ncuim-bottom-end {
    .ncuim-toast-show {
      transform: translate3d(0, -16px, 0);
      animation: ncuim-toast-show-bottom 0.3s;
    }
    .ncuim-toast-hide {
      animation: ncuim-toast-hide-bottom 0.3s;
    }
  }

  &.ncuim-toast {
    box-sizing: border-box;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    overflow-y: hidden;

    .ncuim-container {
      opacity: 1;
      box-sizing: border-box;
      grid-column: 1/4 !important;
      grid-row: 1/4 !important;
      grid-template-columns: min-content auto min-content;
      overflow-y: hidden;
      pointer-events: auto;
      width: 100%;
      max-width: 100%;
      min-width: unset;
      min-height: unset;
      max-height: unset;
      box-shadow: 2px 2px 5px rgba(34, 34, 34, 0.2);
      border-radius: 4px;
    }

    &.ncuim-top-start {
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
    }

    &.ncuim-top {
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    &.ncuim-top-end {
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
    }

    &.ncuim-center-start {
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
    }

    &.ncuim-center {
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.ncuim-center-end {
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
    }

    &.ncuim-bottom-start {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
    }

    &.ncuim-bottom {
      top: auto;
      right: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &.ncuim-bottom-end {
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
    }
  }

  &.horizontal {
    width: 652px;
    &.ncuim-toast {
      .ncuim-container {
        .ncuim-wrapper {
          padding: 21px 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .ncuim-html-container {
            padding: 0;
          }

          .ncuim-content {
            align-items: flex-start;

            &.has-button {
              padding-top: 0px;
              height: unset;
            }
          }
          .ncuim-close {
            top: 29px;
            right: 16px;
          }

          .ncuim-buttons {
            justify-content: flex-end;
            height: unset;
            margin-right: 44px;
            .ncuim-button__cancel {
              width: auto;
              margin-right: 17.5px;
              color: var(--ncui-black068);
              font-size: 15px;
              line-height: 22px;
              background-color: transparent;
            }
            .ncuim-button__confirm {
              width: 74px;
              height: 40px;
              border-radius: 20px;
              background-color: var(--ncui-point);
              font-size: 15px;
              line-height: 22px;
              color: var(--ncui-ui-white);
            }
          }
        }
      }
    }
  }

  &.vertical {
    width: 340px;
    &.ncuim-toast {
      .ncuim-container {
        .ncuim-wrapper {
          padding: 16px 12px 8px 12px;
          .ncuim-html-container {
            padding: 0;
          }

          .ncuim-content {
            align-items: flex-start;

            &.has-button {
              padding-top: 0px;
              height: calc(100% - 46px);
              @include bp(pc) {
                height: calc(100% - 46px);
              }
            }
          }

          .ncuim-buttons {
            border-top: 1px solid var(--ncui-gray016);
            justify-content: flex-end;

            .ncuim-button__cancel {
              width: auto;
              margin-right: 14px;
              color: var(--ncui-black068);
              font-size: 14px;
              line-height: 20px;
              background-color: transparent;
            }
            .ncuim-button__confirm {
              width: 70px;
              height: 30px;
              border-radius: 17px;
              background-color: var(--ncui-button-normal);
              font-size: 14px;
              line-height: 20px;
              color: var(--ncui-ui-white);
            }
          }
        }
      }
    }
  }
}
