

.ncui-searchform {
  text-align: center;
  padding: 28px 18px;
  background-color: var(--ncui-gray004);
  @include breakpoint(pc) {
    padding-left: 0;
    padding-right: 0;
  }
}

form[data-name="suggest_form"]:not([data-use-default-css="false"]) {

  position: relative;
  max-width: 600px;
  margin: 0 auto;
  z-index: 1;

  input::-ms-clear,
  input::-ms-reveal{
    display:none;width:0;height:0;
  }
  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration{
    display:none;
  }

  * {
    box-sizing: border-box;
  }
  button {
    padding: 0;
    font-size: inherit;
    color: inherit;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    -webkit-appearance: button;
  }

  [data-name="suggest_input"] {
    width: 100%;
    height: 40px;
    padding: 6px 66px 6px 10px;
    font-size: 15px;
    border-radius: 4px;
    color: var(--ncui-black093);
    border: 1px solid var(--ncui-gray012);
    background-color: var(--ncui-bg);
    outline: none;
    -webkit-appearance: none;
    &::placeholder {
      color: var(--ncui-black045);
    }
    [dir=rtl] & {
      padding-left: 66px;
      padding-right: 10px;
    }
  }

  [data-name="suggest_delete"] {
    display: none;
    position: absolute;
    right: 40px;
    top: 8px;
    width: 22px;
    height: 22px;
    text-align: left;
    text-indent: -9999em;
    white-space: nowrap;
    overflow: hidden;
    background-size: 22px 22px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsBAMAAADsqkcyAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAeUExURQAAAEdwTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEqYTKYAAAAKdFJOU3MAQAtOFQEqaD2gPMrnAAAA6klEQVQoz42TOw7CMBBERyF8WmPCp4Mb4IIeKtogGkoQFV2QOAAUHICG87JxvPkAI9jCct6O4rV3FuZr4BceLraHD2wvV6C3dk2cLOEj3tSxDVS4q+EMZXQrfEItnoqHyzqODwFP0IhHwFkTdwt8xFs4jweyu08LEu1kWXmcym7TL/AskaXtcV7HzHh5ZPJ0nGNbfWvWCR6hBCGJs+ABSnkQy5kwY6hcxbgJ3kPlKkZH8BwqVzFaglOoXMVSOMXkJ+RIUiC5Drk8eSrysKwNpGn2e4uJIZh9iNmINZmRme3JkLCRYgP43xS/AMTZNdeX2q0HAAAAAElFTkSuQmCC');
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    [class=dark-mode] & {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsBAMAAADsqkcyAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAbUExURUdwTP///////////////////////////////xb9HQ4AAAAJdFJOUwBhWhAwTwZAIEaabHQAAADaSURBVCjPnZM9DsIwDIUrFQqrS1FnVECMUHEAfsQOqBJrxAlgYEecnODYaVp4AvGWJF+eHDtxougvLW/HyWnbgsmOWJVp0A2JspA/yGtW0w4FWvkQ1xDnGqZHDV0Er5s4c7RPLd0Zn9t46mOkI0d45CjJa1aUDrvRaNJFzPZ0Xmrq3WAtu3uLF+SBbNLAZ81EzHymVG6Rmim3+EBqVzMNa2ztamYsDmuP/fQzJhwEHAkSBOWA4sFVgYt9f4bU4EdDTwwaArQPajbQmqCRUdsHn2Rsvn8p9AF/1BPwUEFHwKCo5gAAAABJRU5ErkJggg==');
    }
    [dir=rtl] & {
      left: 40px;
      right: auto;
    }
  }

  [data-name="suggest_submit"] {
    position: absolute;
    right: 11px;
    top: 9px;
    width: 22px;
    height: 22px;
    text-align: left;
    text-indent: -9999em;
    white-space: nowrap;
    overflow: hidden;
    background-size: 22px 22px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsBAMAAADsqkcyAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAtUExURUdwTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAK22XqcAAAAPdFJOUwCtLY9eBw+UnX+lGlE4SpvMOEkAAADISURBVCjPY2CgPVCZvqrSCV2Q9YQgCPQEoArfECzRDt3kLtiLIsoiaASmlQUdkIUnykEZDyWRFUspQFlMC5GUXxSCMxVlEc5ofABn80nAHcMjjGSg4QEYi0MISVixAc4qQBJmh6txRHYsiwjc1QpIwkxwlxduQBLmFoexFiIHEKsUAWEchuCwEocDcXgH1fOtBIIKR8DiiAZckYYUxYJmWBOEs3ACtuTDZmiGNbEloyiHJE2g41CVIwCachiglnJJrMJss2mbHwFvGC7PnLTudgAAAABJRU5ErkJggg==');
    [class=dark-mode] & {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsBAMAAADsqkcyAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUdwTP///////////////////////////////////////////////////4sGkswAAAAOdFJOUwCZgCdTBg2LcJIXRzFCgC1XAgAAAMdJREFUKM9jYKA9MGmaqeGMLsi6XRAEqgNQhY8LqliFLnYSrEERZRFUBtNGgg7Iwo2yUMZFCWTFkgZQFvNEJOUHheFMQxmEMwovwNm84nDHcAshGai4AcZiF0YSNiyAsxSQhJngahyRHcsiAne1AZIwM9zliguQhLng9k9EDiBWSQLCOAzBYSUOB+LwDg7P4wgqHAGLIxpwRRpSFAuqYU0QTkIJ2JIPm6Ia1sSWhKIckjSBjkNVjgBoymGAWsolsAqzddE2PwIAPnkp4Malv5IAAAAASUVORK5CYII=')
    }
    [dir=rtl] & {
      left: 11px;
      right: auto;
    }
  }

  [data-name=suggest_list],
  [data-name=suggest_recent] {
    display: none;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    padding: 0;
    border-radius: 4px;
    border: solid 1px var(--ncui-gray012);
    background-color: var(--ncui-bg);

    ul {
     list-style: none;
      margin: 0;
      padding: 0;
      &:empty {
        height: 0;
      }
    }
    li {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 7px 16px;
      text-align: left;
      font-size: 15px;
      color: var(--ncui-black068);
      &.focus, &:focus, &:hover {
        cursor: pointer;
        background-color: var(--ncui-gray004);
      }
      mark {
        color: var(--ncui-point-text);
        background-color: transparent;
      }
    }

    input[type=button] { // 최근 검색어 삭제 아이콘
      width: 16px;
      height: 16px;
      text-align: left;
      text-indent: -9999em;
      white-space: nowrap;
      overflow: hidden;
      border: 0 none;
      cursor: pointer;
      margin-left: auto;
      background-color: transparent;
      background-size: 16px 16px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAeUExURUdwTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFpVxF4AAAAKdFJOUwCtXEIDdFENkhr47CHdAAAAgElEQVQoz7XPzQ2AIAwG0AaMA5CQeIQR2IDECUwYgBGMMziAI6vQH3rXXtq079AP4JNaebJ7aynQAifjCPjch4gkFbwgYUCEAZIBdDKARhR4iQIP8RrAtJ06j12cXqQSgwI+G6eBJOIUI+k/CMEnhdCTRDgFEUkRa2sHp5gv+KduZbwOqTngEiwAAAAASUVORK5CYII=');
      [class=dark-mode] & {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAYUExURUdwTP///////////////////////////x1LVb4AAAAIdFJOUwCZUTtnD0iB0ybNtwAAAHlJREFUKM+1z80NgCAMBWCiiQOQJl5hBDbADYgTOAL7n9TSX87KpU3zhbwXwidv1a3hKIkPtC2RAdCSiZRKByICmAggYsAgBiBx4CUOPARgyn/0qc8e/aHUnBwAbSQtLMEMlowMSiikEg7JRFow0Rb5wnHK51sL/7wbEa0Kgp9EdtkAAAAASUVORK5CYII=');
      }
      [dir=rtl] & {
        text-align: right;
        margin-right: auto;
        margin-left: 0;
      }
      &:hover {
        opacity: 0.7
      }
    }
  }

  [data-name=suggest_recent-none] {
    padding: 13px 0;
    font-size: 14px;
    color: var(--ncui-black045);
    text-align: center
  }

  [data-name=suggest_recent-del] button {
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    color: var(--ncui-black045);
    background-color: var(--ncui-gray004);
  }

}
