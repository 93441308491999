/* download page */
.download {

  //공통
  h2, h3 {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    color: var(--ncui-black100);
  }

  h2 {
    margin: 0 18px 12px;
    @include bp(pc) {
      margin: 0 0 21px;
    }
  }

  //아이콘 - 다운로드
  [class^="icon-"] {
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    @include hide-text();
  }

  //설치 정보
  &-setup {
    margin: 0 auto 40px;
    background-color: var(--ncui-gray004);
    @include bp(pc) {
      margin: -55px -40px 60px;
    }

    h2 {
      display: none;
    }

    .setup-list {
      display: flex;
      flex-flow: column nowrap;
      margin: 0;

      > li {
        min-height: 160px;
        padding: 40px 18px;
        border-top: 1px solid var(--ncui-white100);
        @include bp(pc) {
          min-height: 192px;
          padding: 60px 40px;
        }
      }
    }

    @each $logo in 'purple', 'daum' {
      .setup-#{$logo} {
        .setup-logo {
          @if $logo == 'purple' {
            width: 152px;
          } 
          @if $logo == 'daum' {
            width: 78px;
            background-image: url("/img/official/download/setup_logo_#{$logo}.png");
          }          
          height: 78px;
          flex-shrink: 0;
          margin: 0 26px 0 0;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          @include hide-text();
        }
      }
    }

    .setup-wrap {
      display: block;
      max-width: 1180px;
      margin: 0 auto;

      @include bp(pc) {
        display: flex;
      }
    }

    .setup-content {
      position: relative;
      width: 100%;
      margin-top: 10px;

      @include bp(pc) {
        margin-top: 0;
      }
    }

    .setup-title {
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      color: var(--ncui-black093);

      @include bp(pc) {
        font-size: 26px;
        padding-right: 200px; // 960화면에서 텍스트가 길 경우 버튼이 겹침
      }
    }

    .setup-desc {
      margin: 12px 0 0;
      font-size: 14px;
      line-height: 20px;
      color: var(--ncui-black045);
      @include bp(pc) {
        margin: 8px 0 0;
      }

      .ic-purple-store {
        display: inline-block;
        width: 17px;
        height: 17px;
        vertical-align: top;
        background: url('/img/official/download/ic_purple_store.png');
        background-size: 100% auto;
      }

      .installed {
        margin-top: 10px;
        font-weight: 700;
        color: var(--ncui-black068);

        a, button {
          text-decoration: underline;
          color: var(--ncui-point-text080);
          cursor: pointer;
        }
      }
    }

    .setup-btn-download {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      margin: 15px 0 0;
      padding: 0 38px;
      border-radius: 100px;
      background-color: var(--ncui-point);
      @include bp(pc) {
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px 0 0;
      }

      .icon-download {
        width: 16px;
        height: 23px;
        margin: 3px 10px 0 0;
        // background: url("/img/official/download/setup_ic_download.png");
        background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAfUlEQVQ4je2S0Q2AMAgF0Qk6giO4kY7gBrqRo3QER3CEM00waSrWVn/ljwfvIATJBUC2QUTap4YfUAEAXIkB6EwR2IEh0UnyEdjMYQoJxcUCAJPW++x6MeQEhFz16/o3EK8mqswRxAGrAnzpgS1QONo7c2k0wPwF8O0TReQAzMKI+eaiTuMAAAAASUVORK5CYII=");
      }

      .text {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  //게임 시스템 권장 사양
  &-spec {
    max-width: 1180px;
    margin: 0 auto 40px;
    @include bp(pc) {
      margin: 0 auto 56px;
    }

    .spec-wrap {
      display: flex;
      flex-flow: column nowrap;
      gap: 20px;
      @include bp(pc) {
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 40px;
      }
    }

    .spec-table {
      width: 100%;

      thead th {
        height: 38px;
        font-size: 14px;
        color:var(--ncui-black068);
        font-weight: normal;
        background-color: var(--ncui-gray004);
        text-align: center;
        vertical-align: middle;
      }

      tbody {
        tr:not(:first-child) {
          th, td {
            border-top: 1px solid var(--ncui-gray008);
          }
        }

        th, td {
          height: 45px;
          padding: 8px 18px 7px 18px;
          font-size: 13px;
          font-weight: normal;
          line-height:  16px;
          vertical-align: middle;
          @include bp(pc) {
            padding: 8px 22px;
            line-height: 28px;
            font-size: 14px;
          }
        }

        th {
          color: var(--ncui-black045);
          text-align: left;
        }

        td {
          color: var(--ncui-black093);
          text-align: right;
        }
      }
    }
  }

  //게임 드라이버
  &-driver {
    max-width: 1180px;
    margin: 0 18px 40px;
    border-top: 5px solid var(--ncui-gray008);
    border-bottom: 5px solid var(--ncui-gray008);
    @include bp(pc) {
      margin: 0 auto 49px;
    }

    h2 {
      display: none;
    }

    .driver-list {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 20px 0 15px;
      @include bp(pc) {
        flex-direction: row;
        // max-width: 840px;
        // padding: 60px 40px;
        padding: 60px 0;
      }

      > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 24px 0;
        @include bp(pc) {
          padding: 0 20px 16px;
        }

        & + li {
          content: '';
          border-top: 1px solid var(--ncui-gray008);
          @include bp(pc) {
            border: 0;
            border-left: 1px solid var(--ncui-gray008);
          }
        }

        // .driver-circle 배경색 지정
        @for $i from 1 through 5 {
          &:nth-of-type(#{$i}) > a {
            background-color: var(--ncui-point-download#{$i});
          }
        }
      }
    }

    .driver-circle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;
      width: 200px;
      height: 200px;
      border-radius: 100%;
      font-size: 18px;
      color: #fff;
      overflow: hidden;
      @include bp(pc) {
        width: 168px;
        height: 168px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color 0.2s;
      }

      &:hover:before {
        background-color: rgba(#000, 0.16);
      }

      > span {
        z-index: 5;
      }

      .volume {
        position: absolute;
        margin-top: -2px;
        font-size: 14px;
      }
    }

    .icon-download {
      width: 26px;
      height: 21px;
      margin: 25px auto 0;
      // background: url("/img/official/download/driver_ic_download.png");
      background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAVCAYAAABYHP4bAAAAxUlEQVRIie2UYQ3CMBCF32GASZgEcDAJQwEWcAAOhgMkTAI4mASYAnDwkSa3ZFkWoA0j/NhLmuyau/uuL+0UIyAD6qgi1yIyP5O0+gUoWTNoBv0JCKiAPKYRUACHKJCkVtIZKD+E7CSdQk3McF1xDlz7U3Z7g7yj50U5MAZr3MqsD/I4nLoO3+96vbx1ZnYzs7WHjaS8G8Dji5ltzOyRfJqhgD1w9xWs2n6t+QisdLuS/uCzkmT+KKuJ7SsCKLyB5ZQUM2ufPuCI9k07zGIAAAAASUVORK5CYII=");
    }

    .driver-text {
      margin: 20px 0 0;
      font-size: 15px;
      color: var(--ncui-black093);
      text-align: center;
      @include bp(pc) {
        margin: 24px 0 0;
      }
    }
  }

  //클라이언트 안내
  &-client {
    max-width: 1180px;
    margin: 0 auto 40px;
    @include bp(pc) {
      margin: 0 auto 60px;
    }

    .client-list {
      display: flex;
      flex-flow: column nowrap;
      gap: 22px;
      margin: 0;
      @include bp(pc) {
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 40px;
      }

      > li {
        width: 100%;
        padding: 0 18px;
        @include bp(pc) {
          padding: 0;
        }
      }
    }

    .client-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
      border: 1px solid var(--ncui-gray012);
      background-color: var(--ncui-gray002);

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%;
        overflow: hidden;
      }
    }

    .client-title {
      display: none;
      margin: 19px 0 7px 0;
      font-size: 16px;
      color: var(--ncui-black093);
      @include bp(pc) {
        margin: 17px 0 5px 0;
      }
    }

    .client-desc {
      margin: 19px 0 0;
      font-size: 14px;
      color: var(--ncui-black045);

      @include bp(pc) {
        margin-top: 21px;
      }

      > li {
        position: relative;
        padding: 0 0 0 10px;
        line-height: 22px;

        &:before {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          width: 2px;
          height: 2px;
          background-color: var(--ncui-gray040-hex);
        }
      }
    }

    //추가는 여기에
    @each $client-bi in 'tl', 'lineage', 'lineage2', 'aion', 'bns', 'lovebeat', 'purple', 'daum', 'bnsneo' {
      .client-#{$client-bi} {
        .client-logo {
          // @if ($client-bi == purple) or ($client-bi == daum) {
          //   background-color: #EEF4FE;
          // } @else {
          //   background-color: var(--ncui-point);
          // }
          &::before {
            background-image: url("/img/official/download/client_logo_#{$client-bi}.png");
          }
        }
      }
    }
  }

  //FAQ
  &-faq {
    display: none;
    max-width: 1180px;
    margin: 0 auto 40px;
    @include bp(pc) {
      display: block;
      margin: 0 auto 60px;
    }

    > h2 {
      display: none;
    }

    .faq-list {}

    .faq-question {
      display: block;
      position: relative;
      padding: 16px 80px 15px 16px;
      font-size: 14px;
      color: var(--ncui-black093);
      background-color: var(--ncui-gray004);
      cursor: pointer;
      @include bp(pc) {
        padding: 15px 0 14px 30px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 66px;
        background-color: var(--ncui-gray004);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 28px;
        width: 0;
        height: 0;
        margin-top: -3px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid var(--ncui-black093);
        transform: rotate(0deg);
      }

      & ~ dt {
        margin-top: 2px;
      }
      &.is-active{
        &:after {
          transform: rotate(180deg);
        }
      }
      &.is-active + .faq-answer {
        display: block;
      }
    }

    .faq-answer {
      display: none;
      margin: 0;
      padding: 20px 15px;
      background-color: var(--ncui-gray010-hex);
      @include bp(pc){
        padding: 45px 80px;
      }

      li {
        margin-bottom: 28px;

        > p {
          margin-bottom: 15px;
          font-size: 14px;
          color: var(--ncui-black093);

          @include bp(pc){
            margin-bottom: 20px;
          }
        }

        > img {
          margin: 0 16px 16px 0;
          max-width: 100%;
          vertical-align: top;
          
        }
      }
    }

    .faq-cs{
      margin-top: 11px;
      margin-right: 18px;
      font-size: 13px;
      color: var(--ncui-black045);
      text-align: right;
      @include bp(pc) {
        margin-right: 0;
      }

      > a {
        padding: 0 0 0 10px;
        color: var(--ncui-point);
        &:before {
          content: '> ';
        }
      }
    }
  }

  // erl for lineage, lineage2
  .erl-download-agree {
    width: 100%;
    margin: 20px 0;
    font-size: 13px;
    color: var(--ncui-black045);
    text-align: center;
    * {
      vertical-align: middle;
      cursor: pointer;
    }
    #check_erl { 
      -webkit-appearance: auto;
    }
  }
  .erl-download-info {
    max-width: 360px;
    text-align: left;
    padding: 18px;
    border: 1px solid var(--ncui-gray008);
    background-color: var(--ncui-gray004);
    font-size: 13px;
    color: var(--ncui-black068);

    ul {
      li {
        position: relative;
        padding: 0 0 7px 25px;
        a {
          color: var(--ncui-point-text);
          text-decoration: underline;
        }
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          text-align: center;
          color: var(--ncui-black045);
          border-radius: 50%;
          border: 1px solid var(--ncui-gray004);
          background-color: var(--ncui-gray002);
          
        }
        @for $i from 1 through 2 {
          &:nth-child(#{$i})::before {
            content : '#{$i}';
          }
        }
      }
    }
  }
}

// 4mmo 다운로드 페이지 재선언
//html[data-service=lineage] {
//  body.page-download .wrap-contents {
//    padding: 0;
//
//    @include bp(pc) {
//      max-width: 100%;
//      padding: 55px 40px 0 40px;
//    }
//  }
//}

//공통
@each $service-logo in 'tl', 'lineage', 'lineage2', 'aion', 'bns', 'lovebeat', 'bnsneo', 'lineage2mru' {
  html[data-service='#{$service-logo}'] {
    body.page-download {
      .setup-purple .setup-logo {
        background-image: url('/img/official/download/setup_logo_#{$service-logo}.png');
      }
    }
  }
  
  //darkmode
  html.dark-mode[data-service='#{$service-logo}'] {
    body.page-download {
      .setup-purple .setup-logo {
        background-image: url('/img/official/download/setup_logo_#{$service-logo}_dark.png');
      }

      @if $service-logo == 'bns' or $service-logo == 'bnsneo' {
        .client-#{$service-logo} {
          .client-logo {
            &::before {
              background-image: url("/img/official/download/client_logo_#{$service-logo}_dark.png");
            }
          }
        }
      }
    }
  }
}

html[data-service=tl] { 
  body.page-download {
    .download-spec {
      .spec-wrap {
        overflow-x: auto;
        @include bp(pc) {
          overflow-x: hidden;
        }

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          padding: 0 10px;
        }
        &::-webkit-scrollbar-thumb {
          margin: 5px;
          background-clip: padding-box;
          border-width: 0;
          border-style: solid;
          border-radius: 5px;
          border-color: transparent;
          background-color: var(--ncui-gray012);
        }
        &::-webkit-scrollbar-track {
          background-color: var(--ncui-gray008);
        }
      }

      .spec-table {
        min-width: 650px;
        border-collapse: separate;
        border-spacing: 0;
        border-bottom: 1px solid var(--ncui-gray008);

        thead th:first-child,
        tbody th {
          width: 90px;
          position: sticky;
          left: 0;
          z-index: 1;
          @include bp(pc) {
            width: 110px;
          }
        }

        thead th {
          border-right: 1px solid var(--ncui-bg);
          background-color: var(--ncui-gray004-hex);
          &:last-child {
            border-right: 0;
          }
        }

        tbody th,
        tbody td {
          padding: 8px 14px 7px 14px;
          border-right: 1px solid var(--ncui-gray008);
          background-color: var(--ncui-bg);
          @include bp(pc) {
            padding: 8px 14px;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }

      .spec-noti {
        position: relative;
        margin: 14px 15px 0;
        padding-left: 10px;
        font-size: 14px;
        line-height: 22px;
        color: var(--ncui-black045);

        @include bp(pc) {
          margin: 14px 0px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            width: 2px;
            height: 2px;
            background-color: var(--ncui-gray040-hex);
        }
      }
    }

    // 게임등급표기 (only EN)
    .download-grade {
      display: none;
      max-width: 1180px;
      margin: 0 auto 40px;
      @include bp(tablet) {
        display: block;
      }
      @include bp(pc) {
        margin: 0 auto 60px;
      }
  
      .grade-list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 4px;
        height: 86px;
        margin: 0 18px;
        @include bp(pc) {
          margin: 0;
        }
  
        li {
          display: flex;
          width: 100%;
          background-color: var(--ncui-gray004);
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          flex-basis: 0;
          @include bp(pc) {
            padding: 0;
          }
          &.grade-15 {
            flex-grow: 2;
            min-width: 430px;
            padding-left: 2px;
          }
        }
      }

      .grade-tw {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--ncui-black045);
        font-size: 12px;
        line-height: 18px;

        img {
          margin-right: 16px;
        }
      }
    }
  }
}


html[data-service=lineage2mru] { 
  body.page-download {
    .download-spec {
      .spec-wrap {
        overflow-x: auto;
        @include bp(pc) {
          overflow-x: hidden;
        }

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          padding: 0 10px;
        }
        &::-webkit-scrollbar-thumb {
          margin: 5px;
          background-clip: padding-box;
          border-width: 0;
          border-style: solid;
          border-radius: 5px;
          border-color: transparent;
          background-color: var(--ncui-gray012);
        }
        &::-webkit-scrollbar-track {
          background-color: var(--ncui-gray008);
        }
      }

      .spec-table {
        min-width: 650px;
        border-collapse: separate;
        border-spacing: 0;
        border-bottom: 1px solid var(--ncui-gray008);

        thead th:first-child,
        tbody th {
          width: 90px;
          position: sticky;
          left: 0;
          z-index: 1;
          @include bp(pc) {
            width: 110px;
          }
        }

        thead th {
          border-right: 1px solid var(--ncui-bg);
          background-color: var(--ncui-gray004-hex);
          &:last-child {
            border-right: 0;
          }
        }

        tbody th,
        tbody td {
          padding: 8px 14px 7px 14px;
          border-right: 1px solid var(--ncui-gray008);
          background-color: var(--ncui-bg);
          @include bp(pc) {
            padding: 8px 14px;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
      
    }    
  }
}