// my character list
// ===============================

// 작성/댓글/생성된 캐릭터 없는 경우 (혈맹에서도 사용)
// ------------------------------
.profile-none {
  padding: 150px 0 90px 0;
  font-size: 18px;
  color: #c7c7cc;
  text-align: center;

  .btn-wrap {
    margin-top: 25px;

    .btn-preorder-guild-wrap {
      display: block;
      margin-top: 10px;
    }

    .btn-preorder-guild {
      display: inline-block;
      color: #1f1f1f;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: "";
        display: inline-block;
        margin-left: 6px;
        @include triangle(10px 6px, #b8b8b8, right);
      }
    }
  }

  @include bp(pc) {
    padding: 150px 0;
  }
}

.guild-none {
  .profile-none {
    color: #686868;
    font-weight: normal;
  }
}

.profile-none-msg {
  margin-top: -65px;
  padding: 0 20px;
  font-size: 14px;
  color: #686868;
  line-height: 1.4;
  text-align: center;

  .btn-go-agit {
    display: inline-block;
    margin-top: 20px;
    color: #1f1f1f;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: "";
      display: inline-block;
      margin-left: 6px;
      @include triangle(10px 6px, #b8b8b8, right);
    }
  }

  @include bp(pc) {
    margin-top: -120px;
  }
}

// 내 캐릭터 리스트
// ------------------------------
.my-character-list-wrap {
  margin-top: 6px;

  @include bp(pc) {
    margin-top: -40px;
  }
}

.my-character-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include clearfix;

  @include bp(pc) {
    border-bottom: solid 1px var(--ncui-gray008-hex);
  }
}

.my-character-items {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  min-height: 50px;
  margin: 0 16px;
  padding: 10px 0 10px 0;
  list-style-type: none;
  border-bottom: solid 1px var(--ncui-gray008-hex);

  @include bp(pc) {
    float: left;
    width: 50%;
    min-height: 104px;
    margin: 1px 0 -1px 0;
    padding: 22px 24px;
    [dir=rtl] & {
      float: right;
    }
  }

  .thumb-wrap {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    [dir=rtl] & {
      margin: 0 0 0 12px;
    }
    @include bp(pc) {
      margin-right: 18px;
      [dir=rtl] & {
        margin: 0 0 0 18px;
      }
    }

    .thumb {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: top;
    }

    @include bp(pc) {
      .thumb {
        width: 80px;
        height: 80px;
      }
    }
  }

  .attach-list-items {
    position: absolute;
    bottom: 0;
    right: 0;

    min-width: 50px;
    min-height: 50px;
    background-color: transparent;

    @include bp(pc) {
      width: 100%;
      height: 100%;
    }

    .fileUploadInput input,
    .fe-icon-photo { // 리니지2M NCJ/NCT/NU/RU 대응
      position: absolute;
      cursor: pointer;
      right: -7px;
      bottom: -5px;
      width: 25px;
      height: 25px;
      background: url("/img/official/my/btn_upload.png") no-repeat;
      background-size: 25px auto;
      @include hide-text();

      @include bp(pc) {
        right: -5px;
        bottom: 0;
        width: 32px;
        height: 32px;
        background-size: 32px auto;
      }
    }

    iframe {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .character {
    display: block;
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    color: var(--ncui-black093);
    .name {
      font-weight: normal;
    }

    @include bp(pc) {
      display: inline-block;
      margin-top: 0;
      vertical-align: middle;
      font-size: 16px;
    }

    @include bp(max) {
      display: inline-block;
    }
  }

  .server-guild {
    font-size: 13px;
    color: var(--ncui-black045);
    @include bp(pc) {
      font-size: 14px;
    }

    span + span {
      &:before {
        @extend .guild-bar;
        vertical-align: top;
        margin-top: 1px;
        [dir=rtl] & {
          margin-top: 4px;
        }
        @include bp(tablet) {
          margin-top: 5px;
        }
      }
    }

    .server {
      font-weight: normal;
      font-style: normal;
      color: var(--ncui-black068);
      &:before {
        display: none !important;
      }
    }

    .guild {
      display: inline-block;
      font-weight: normal;
      font-style: normal;
      color: var(--ncui-point-text);
      &:empty{
        &:before{
          display: none;
        }
      }
      &:before {
        display: inline-block;
        margin-top: 3px;
      }
      @include bp(tablet) {
        margin-top: 0;
        &:before{
          margin-top: 5px;
        }
      }
    }
  }
}
