.ncuim-backdrop {
    display: grid;
    position: fixed;
    z-index: 9100030;
    box-sizing: border-box;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    grid-template-areas:
        'top-start top top-end'
        'center-start center center-end'
        'bottom-start bottom-center bottom-end';
    grid-template-rows: repeat(3, minmax(min-content, auto));
    overflow-x: hidden;
    transition: background-color 0.15s;
    height: 100%;
    &-show {
        background-color: rgba(0, 0, 0, 0.8);
        .modal-container {
            opacity: 1;
            transform: scale(1);
        }
    }
    &-hide {
        background-color: transparent !important;
    }

    &.ncuim-top-start,
    &.ncuim-center-start,
    &.ncuim-bottom-start {
        // 왜 궅이 위에께 주석이었고, 밑에 걸로 되어 있었을까? (알아봅시다)

        // grid-template-columns: minmax(0, 1fr) auto auto;
        grid-template-columns: auto minmax(0, 1fr) auto;
    }

    &.ncuim-top,
    &.ncuim-center,
    &.ncuim-bottom {
        grid-template-columns: auto minmax(0, 1fr) auto;
    }

    &.ncuim-top-end,
    &.ncuim-center-end,
    &.ncuim-bottom-end {
        grid-template-columns: auto auto minmax(0, 1fr);
    }

    &.ncuim-top-start > .ncuim-container {
        align-self: start;
    }
    &.ncuim-top > .ncuim-container {
        grid-column: 2;
        align-self: start;
        justify-self: center;
    }
    &.ncuim-top-end > .ncuim-container {
        grid-column: 3;
        align-self: start;
        justify-self: end;
    }

    &.ncuim-center-start > .ncuim-container {
        grid-row: 2;
        align-self: center;
    }
    &.ncuim-center > .ncuim-container {
        grid-column: 2;
        grid-row: 2;
        align-self: center;
        justify-self: center;
    }
    &.ncuim-center-end > .ncuim-container {
        grid-column: 3;
        grid-row: 2;
        align-self: center;
        justify-self: end;
    }

    &.ncuim-bottom-start > .ncuim-container {
        grid-column: 1;
        grid-row: 3;
        align-self: end;
    }
    &.ncuim-bottom > .ncuim-container {
        grid-column: 2;
        grid-row: 3;
        justify-self: center;
        align-self: end;
    }
    &.ncuim-bottom-end > .ncuim-container {
        grid-column: 3;
        grid-row: 3;
        align-self: end;
        justify-self: end;
    }

    &.ncuim-grow-row > .ncuim-container,
    &.ncuim-grow-fullscreen > .ncuim-container {
        grid-column: 1/4;
        width: 100%;
    }

    &.ncuim-grow-column > .ncuim-container,
    &.ncuim-grow-fullscreen > .ncuim-container {
        grid-row: 1/4;
        align-self: stretch;
    }

    .ncuim-container {
        display: flex;
        background-color: var(--ncui-bg);
        border-radius: 4px;
        min-width: $ncuim-modal-min-width-m;
        max-width: $ncuim-modal-max-width-m;
        max-height: 90%;
        min-height: $ncuim-modal-min-height-m;

        &-show {
            animation: ncuim-show 0.1s;
        }

        &-hide {
            animation: ncuim-hide 0.15s;
        }

        @include bp(pc) {
            min-width: $ncuim-modal-min-width;
            max-width: $ncuim-modal-max-width;

            min-height: $ncuim-modal-min-height;
            max-height: $ncuim-modal-max-height;
        }

        .ncuim-wrapper {
            position: relative;
            width: 100%;
        }

        .ncuim-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            padding-top: $ncuim-modal-content-padding;
            height: 100%;
            &.has-button {
                height: calc(100% - $ncuim-modal-button-height-m);
                @include bp(pc) {
                    height: calc(100% - $ncuim-modal-button-height);
                }
            }
        }

        .ncuim-close {
            position: absolute;
            z-index: 10;
            width: 22px;
            height: 22px;
            top: 10px;
            right: 10px;
            padding: 0;
            border: none;
            cursor: pointer;
            justify-self: end;
            background: url('/img/md-modal/close.svg');
            background-size: cover;
        }

        .ncuim-title {
            font-weight: 400;
            word-break: keep-all;
            margin: 0 auto;
            max-width: 220px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: var(--ncui-black093);
            word-wrap: break-word;

            @include bp(pc) {
                max-width: 280px;
            }
        }

        .ncuim-html-container {
            z-index: 1;
            justify-content: center;
            padding: 3px 36px 20px 36px;
            overflow: auto;
            overflow-y: overlay;
            word-wrap: break-word;
            word-break: break-word;
            text-align: center;
            font-size: 14px;
            line-height: 21px;
            color: var(--ncui-black045);
            max-height: 413px;
            @include custom-scroll;

            @include bp(pc) {
                max-height: 504px;
            }
        }

        .ncuim-buttons {
            display: flex;
            z-index: 1;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 49px;

            @include bp(pc) {
                height: 53px;
            }

            .ncuim-button {
                &__cancel {
                    background-color: var(--ncui-gray010);
                    color: var(--ncui-black068);
                    font-size: 15px;
                    line-height: 21px;
                    width: 50%;
                    height: 100%;
                    cursor: pointer;
                    border-radius: 0 0 0 4px;

                    &:hover,
                    &:focus {
                        background-color: var(--ncui-gray016);
                    }
                }
                &__confirm {
                    background-color: var(--ncui-point);
                    color: var(--ncui-ui-white);
                    font-size: 15px;
                    line-height: 21px;
                    width: 50%;
                    height: 100%;
                    cursor: pointer;
                    border-radius: 0 0 4px 0;

                    &:hover,
                    &:focus {
                        background-color: var(--ncui-point-hover);
                    }
                }

                &__union {
                    width: 100%;
                    border-radius: 0 0 4px 4px;
                }
            }
        }
    }
}
