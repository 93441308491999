// tab scss

//대표 캐릭터 선택 > 캐릭터 리스트 li
#nc-cnb .ncc-character-item {
    margin-bottom: 1px;
    color: var(--ncui-black093);
    font-size: 14px;
    & > a {
        display: block;
        padding: 14px 18px 14px;
        color: var(--ncui-black093);
        background-color: var(--ncui-gray004);
        cursor: default;
    }
    .ncc-lnb-item__sub {
        display: none;
        padding: 0 0 15px 0;
        margin: 13px 17px 0 17px;
        border-bottom: 1px solid var(--ncui-gray008);

        &:last-child {
            border: 0 none;
        }

        &.is-active {
            display: block;
        }

        li {
            padding: 0;
        }

        .cnb-character-list-items {
            color: #000;
            line-height: 1.4;

            .character-info {
                font-size: 13px;

                button {
                    color: var(--ncui-black068) !important;
                }

                .character {
                    .lv {
                        color: var(--ncui-black068);
                    }
                    .name {
                        color: var(--ncui-black068);
                        font-weight: normal;
                    }
                }

                .classcard,
                .guild {
                    color: var(--ncui-black045) !important;

                    &::before {
                        margin-top: 4px;
                        vertical-align: top;

                        content: '';
                        display: inline-block;
                        height: 11px;
                        margin-left: 4px;
                        padding-right: 6px;
                        border-left: 1px solid var(--ncui-gray012);
                    }
                }

                .guild {
                    &:empty {
                        display: none;
                    }
                }
            }

            .last-access {
                display: block;
                margin-top: 1px;
                color: var(--ncui-black068);
                font-size: 14px;
            }
        }
    }
}
