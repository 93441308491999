.g-cookie-set-dimmed {
  display: none;

  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 1;
  &.is-active{
    display: block;
  }
}

.g-cookie-set-layer-wrap {
  display: none;
  position: absolute;
  // transform: translateY(-50%);
  top: 40px;
  // height: 759px;
  line-height: 1.4;
  // letter-spacing: normal;
  z-index: 2;
  width: calc(100% - 32px);
  // margin: 0 16px;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
  background-color: $bg2;
  border-radius: 5px;
  color: $text4;
  // word-break: keep-all;
  padding-bottom: 40px;
  // letter-spacing: -0.7px;
  max-height: 90%;

  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  &.is-active{
    display: block;
  }

  @include bp(pc){
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px));
    width: 786px;
    // height: 671px;
  }

  li{
    list-style: none;
  }

  label{
    display: block;
    cursor: pointer;
  }

  .g-cookie-set-layer-top{
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0;
    background-color: $bg2;
    .g-cookie-head {
      position: relative;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      .g-cookie-title{
        text-align: center;
        font-size: 18px;
        line-height: 1.39;
        // letter-spacing: -0.42px;
        color: $text2;

      }
      .g-cookie-btn-close{
        position: absolute;
        text-align: center;
        right: 0;
        top:-10px;
        cursor: pointer;
        font-size: 0;
        html[dir=rtl] & {
          right: auto;
          left: 0;
        }
        svg {
          display: none;
        }
        #{$svgDarkMode} {
          display: block;
        }

      }
    }
    .g-cookie-desc{
      margin-top:18px;
      font-size: 13px;
      line-height: 1.38;
      // letter-spacing: -0.42px;
      color: $text4;

      a{
        text-decoration: underline;
      }
    }
  }
  .g-cookie-set-layer-mid{
    background-color: $bg2;;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 20px 0;
    @include bp(pc){
      padding: 27px 20px 0;
      // flex-direction: row;
    }
    .g-cookie-chk-wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
      li{
        // margin-right: 16px;
        margin-bottom: 12px;
        width: 100%;
      }
      @include bp(pc){
        justify-content: center;
        li{
          width: 50%;
          // margin-right: 20px;
          //   html[dir=rtl] & {
          //     margin-right: 0;
          //     margin-left: 20px;
          //   }
        }
      }

      input[type=checkbox]  {
        position: absolute;
        left: -99999px;
        html[dir=rtl] & {
          left: 99999px;
        }
        &+label{
            .label-custom-box{
                width: 22px;
                height: 22px;
                background: url('/img/md-cookieLayer/#{$img-icon-check-off}.svg');
                background-size: cover;
                // border: 1px solid transparent;
                position: relative;
                // border-radius: 50%;
                // background-color: $bg2;
                margin-right: 8px;
                // border-color: $border4;
                html[dir=rtl] & {
                    margin-left: 8px;
                    margin-right: 0;
                }
                // &::before, &::after{
                //   content: '';
                //   position: absolute;
                //   display: block;
                //   top: 50%;
                //   left: 50%;
                //   height: 0;
                //   border-radius: 2px;
                //   background-color: $btn1;
                // }
                // &::before{
                //   width: 8px;
                //   margin: 1px 0 0 -8px;
                //   transform: rotate( 50deg );
                // }

                // &::after{
                //   width: 15px;
                //   margin: -2px 0 0 -6px;
                //   transform: rotate( 120deg );
                // }
            }
        }
        &:checked+label {
            .label-custom-box {
                background: url('/img/md-cookieLayer/#{$img-icon-check-on}.svg');
                background-size: cover;

                // background-color: $bg3;

                // &::before{
                //   height: 2px;
                //   transition: .2s ease-in;
                // }
                // &::after{
                //   height: 2px;
                //   transition: .1s ease-out .15s;
                // }
            }
        }
        &:disabled+label {
        color: $text5;
            .label-custom-box {
                background: url('/img/md-cookieLayer/#{$img-icon-check-on-diabled}.svg');
                background-size: cover;

                // border: none;

                // &::before{
                //   height: 2px;
                //   transition: .2s ease-in;
                // }
                // &::after{
                //   height: 2px;
                //   transition: .1s ease-out .15s;
                // }
            }
        }
      };
      .g-cookie-chk-label {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .label-text{
          flex:1;
          // word-break: break-word;
          font-size: 15px;
          font-weight: normal;
          // letter-spacing: -0.7px;
        }

      }

    }
    .g-cookie-btn-apply-wrap{
      display: flex;
      justify-content: center;
      margin-top:4px;
      @include bp(pc){
        margin-top:12px;


      }
      .g-cookie-btn-apply{
        cursor: pointer;
        background-color: $btn3;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        border-radius: 17px;
        color: $text1;
        font-size: 15px;
        line-height: normal;
        // letter-spacing: -0.42px;
      }
    }

  }
  .g-cookie-set-layer-bottom{
    background-color: $bg2;
    display: flex;
    flex-direction: column;
    padding-top:25px;
    border-radius: 0 0 5px 5px;
    border-top: none;
    @include bp(pc){
      padding-top:31px;
    }
    .g-cookie-tab-wrap{
      display: flex;
      min-height: 40px;
      flex-wrap: wrap;
      border-top: 1px solid $border2;
      li{
        width: 50%;
        // border-top: 1px solid $border2;
        @include bp(pc){
          flex:1
        }
        input[type=radio] {
          position: absolute;
          left: -9999px;
          html[dir=rtl] & {
            left: 99999px;
          }
        }
      }
      .g-cookie-tab-label {
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        justify-content: center;
        height: 100%;
        border-right: 1px solid $border2;
        border-bottom: 1px solid $border2;
        background-color: $btn2;
        font-size: 14px;
        color: $text5;
        // word-break: break-word;


        padding: 2px;
        min-height: 40px;
        font-size: 14px;
        // letter-spacing: -0.42px;
        line-height: normal;

        @include bp(pc){
          padding: 0px 22px;
          line-height: 1.5;
          // letter-spacing: -0.7px;
          min-height: 50px;
        }
        html[dir=rtl] & {
          border-right: none;
          border-left: 1px solid $border2;
        }
      }
      li:last-child{
        .g-cookie-tab-label {
          border-right: none;
          html[dir=rtl] & {
            border-left: none;
          }
        }
      }

      input[type=radio]{
        position: absolute;
        // left: -99999px;
        &:checked+label{
          background-color:$btn4;
          color: $text2;
          @include bp(pc){
            border-bottom: none;
          }
        }
      }

    }
    .g-cookie-info-wrap{
      margin-top:21px;
      padding: 0 7px 20px 20px;

      .g-cookie-info {

        display: none;
        // height: 270px;
        height: 320px;
        overflow-y: scroll;
        overflow-x: auto;
        padding-right: 7px;
        html[dir=rtl] & {
          padding-left: 7px;
          padding-right: 0;
        }
        @include bp(pc){
          height: 310px;
        }

        &.is-active{
          display: block;
        }
        .g-cookie-info-title{
          line-height: 1.38;
          // letter-spacing: -0.42px;
          color: $text4;
          font-size: 13px;
        }
        .g-cookie-info-content-wrap{
          width: 100%;
          overflow:auto;
          margin-top:20px;
          padding-bottom: 7px;
          &::-webkit-scrollbar-thumb{
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .1);

          }
          &::-webkit-scrollbar{
            // -webkit-appearance: none;
            height: 6px;
          }
        }
        .g-cookie-info-content{
          overflow: auto;
          min-width: 746px;
          display: flex;
          flex-direction: column;
          line-height: 1.5;
          // letter-spacing: -0.7px;
          font-size: 12px;
          cursor: default;

          @include bp(pc){
            min-width:auto;

            width: auto;
          }
          .g-cookie-info-content-row{
              color:$text4 ;
              display: flex;
              border-bottom: 1px solid $border3;
            &.g-cookie-info-content-row-head{
              background-color: $bg6;
              font-weight: normal;
            }
            .g-cookie-info-content-col{
              min-height: 36px;
              padding-left: 16px;
              display: flex;
              justify-content: start;
              align-items: center;
              flex: 1;
							// word-break: break-all;
              padding-top:8px;
              padding-bottom:8px;
              min-width: 0;
              p{
                width:100%;
              }
              html[dir=rtl] & {
                padding-left: 0;
                padding-right: 16px;
              }
            }
          }


        }
        &::-webkit-scrollbar-thumb{
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .1);

        }
        &::-webkit-scrollbar{
          // -webkit-appearance: none;
          width: 6px;
        }
        // &::-webkit-scrollbar-corner{
        //   background-color: transparent;
        // }
        // &::-webkit-scrollbar-track{
        //   background-color: rgba(255, 255, 255, .5);
        // }


      }

    }
  }
  &.purple-scroll-bar{
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: rem(14px);
      height: rem(18px);
    }

    &::-webkit-scrollbar-thumb {
      height: rem(6px);
      border: rem(4px) solid rgba(#000000, 0);
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      -webkit-box-shadow: inset -1px -1px 0px rgba(#000000, 0.05), inset 1px 1px 0px rgba(#000000, 0.05);
      background-color: $scroll-bar-thumb;

    }

    &::-webkit-scrollbar-button {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}

