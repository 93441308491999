  body {
      &.ncuim-shown {
          &:not(.modal-no-backdrop):not(.modal-toast) {
              touch-action: none;
              -webkit-overflow-scrolling: none;
              overflow: hidden;
              /* 이외의 브라우저 */
              overscroll-behavior: none;
          }
      }
      &.ncuim-no-backdrop {
          .ncuim-backdrop {
              background-color: transparent !important;
              pointer-events: none;

              .ncuim-container {
                  pointer-events: all;
                  min-height: $ncuim-modal-min-height-m;
              }
          }
      }
  }
