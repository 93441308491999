// profile
// ===============================

.profile-box {
  width: 100%;
  padding: 25px 0 26px 0;
  text-align: center;
  background-color: var(--ncui-gray004);

  @include bp(pc) {
    height: 120px;
    padding: 20px 0;
    margin-top: -20px;
    margin-bottom: 40px;

    .pc-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      margin: 0 auto;
      text-align: left;
    }
  }

  .thumb-wrap {
    display: inline-block;
    .thumb {
      width: calc(170px/2);
      height: calc(170px/2);
      border-radius: 50%;
    }

    @include bp(pc) {
      margin-right: 20px;

      .thumb {
        width: 80px;
        height: 80px;
      }
    }
  }

  .character {
    display: block;
    margin-top: 9px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    color: var(--ncui-black093);

    .name {
      font-weight: normal;
    }

    @include bp(pc) {
      min-height: 23px;
      margin-top: 0;
      vertical-align: middle;
      font-size: 16px;
    }
  }

  .server-guild {
    margin-top: 1px;
    font-size: 13px;

    @include bp(pc) {
      min-height: 23px;
      margin-top: 0;
      margin-left: 4px;
      font-size: 16px;
      vertical-align: middle;
    }

    span {
      color: var(--ncui-black068);

      &:before {
        @extend .guild-bar;
        vertical-align: top;
        margin-top: 3px;

        @include bp(pc) {
          margin-top: 6px;
        }
      }

      &:first-child {
        &::before {
          display: none;

          @include bp(pc) {
            display: inline-block;
          }
        }
      }
    }

    .server {
      font-weight: normal;
      font-style: normal;
      color: var(--ncui-black068);
    }

    .guild {
      font-weight: normal;
      font-style: normal;
      color: var(--ncui-black068);
    }
  }
}
