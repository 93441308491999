// 혈맹관리 레이어는 preorder_clan/_layer 사용
// SNS 공유 레이어
// ===============================

.ly-guild-invite {
    .ly-title { display: none; }
    .ly-contents-wrap { z-index: 0; }
    .invite-msg { text-align: center; }

    .invite-share-wrap {
        clear: both;
        height: 52px;
        margin: 25px auto 0 auto;
        text-align: center;
        @include pie-clearfix();

        .btn {
            display: inline-block;
            width: 52px;
            height: 100%;
            background: url('/img/official/my/guild/sns.gif') no-repeat;
            background-size: auto calc(104px/2);
            @include hide-text();

            &.fb {
                background-position: 0 0;
            }
            &.twitter {
                background-position: -52px 0;
            }
            &.kakao {
                background-position: (-52px*2) 0;
            }
            &.kakaostory {
                background-position: (-52px*3) 0;
            }
            &.url {
                padding-top: 10px;
                background-image: none;
                background-color: #c5c5c5;
                font-size: 14px;
                line-height: 1;
                text-align: center;
                color: #fff;
                text-indent: 0;
            }
        }
    }
}

// 혈맹 출석체크
.ly-guild-attendance {
    text-align: center;

    .ly-title { display: none; }
    .ly-contents-wrap { z-index: 0; }

    .attendance-msg {
        font-size: 18px;
        line-height: 1.4;
    }

    .attendance-desc {
        padding: 15px 0 0 0;
        font-size: 14px;
        color: #8c919a;
        line-height: 1.4;
    }
}