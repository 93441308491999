body.modal-open {
    overflow: hidden;
}

.nc-backdrop {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9100010; // 10000000
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity .15s linear;
    &--show {
        visibility: visible;
        opacity: 1;
    }
    &--blur {
        backdrop-filter: blur(4px);
    }
}

.nc-modal {
    $self: &;
    visibility: hidden;
    z-index: 9100013;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    opacity: 0;

    transition: all 200ms;


    &--center {
        transform: scale(0.9);
    }

    &--top {
        transform: translateY(-50px);
    }

    &--bottom {
        pointer-events: none;
        top: auto;
        bottom: 0;
        transform: translateY(50px);
    }

    &--show {
        visibility: visible;
        opacity: 1;

        &#{$self}--center{
            transform: scale(1);
        }
        &#{$self}--top{
            transform: translateY(0px);
        }
        &#{$self}--bottom{
            transform: translateY(0px);
        }
    }

    &__dialog {
        position: absolute;
        pointer-events: auto;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 328px;
        max-height: 90%;
        min-height: 56px; // 150px;
        transform: translate(-50%, -50%);

        #{$self}--top &{
            top: 10px;
            transform: translate(-50%, 0%);
        }
        #{$self}--bottom &{
            width: calc(100% - 20px);
            bottom: 10px;
            top: auto;
            transform: translate(-50%, 0%);
        }
    }

    &__dialog--full{
        width: 100%;
        max-height: 100%;
    }

    &__close {
        position: absolute;
        padding: 0;
        top: 39px;
        right: 23px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        appearance: none;
        border: none;
        background-color: transparent;
        svg{
            stroke: #1F1F1F;
            transition: transform 150ms;
        }

        &:hover {
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__content {
        text-align: center;
        word-break: keep-all;
        border: 1px solid rgba(34, 34, 34, 0.12);
        background-color: rgba(0,0,0, 0.95); // #fff;
        box-shadow: 2px 2px 5px 0 rgba(0,0,0, 0.25);
        border-radius: 5px;
        overflow: hidden;
        max-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;

        #{$self}--bottom &{
            align-items: unset;
        }
    }

    &__content--full{
        height: 100vh;
        max-height: 100vh;
    }

    &__header {
        padding: 33px 25px 10px;

        #{$self}--bottom &{
            // width: 100%;
            flex: none;
            padding: 16px 16px 15px;
        }
    }

    &__title {
        margin: 0;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        // letter-spacing: -1px;
        text-align: center;
        color: #fff; // rgba(0, 0, 0, 0.93);

        #{$self}--bottom &{
            text-align: left;
            [dir=rtl] &{
                text-align: right !important;
            }
        }
    }

    &__body {
        padding: 0 25px;
        font-size: 15px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        // letter-spacing: -1px;
        text-align: center;
        color: #fff; // rgba(0, 0, 0, 0.45);
        overflow-y: auto;
        word-break: break-all;
        word-wrap: break-word;
        width: 100%;

        #{$self}--bottom &{
            text-align: left;
            padding: 0;
            [dir=rtl] &{
                text-align: right !important;
            }
        }
    }

    &__footer {
        // padding: 5px 0 0;

        &:not(:empty) {
            padding: 16px; // 20px 25px 35px;
            #{$self}--bottom &{
                padding: 8px 0 8px; // 16px 0 16px;
                margin: 0px 14px;
                border-top: 1px solid;
                border-color: rgba(255, 255, 255, 0.1);
                text-align: right;
                [dir=rtl] &{
                    text-align: left !important;
                }
            }
        }

    }

    &__btn {
        padding: 0 16px;
        height: 30px;
        border-radius: 15px;
        border: solid 1px rgba(34, 34, 34, 0.12);
        background-color: #646ada; // #fff;
        color: #fff; // rgba(0, 0, 0, 0.68);
        cursor: pointer;
        overflow: hidden;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            transition: opacity 300ms;
            opacity: 0;
        }
        + &__btn {
            margin-left: 5px;
        }

        &--pri {
            border: none;
            background-color: #b4b4b4;
            color: #fff;
            // margin-right: 5px;
            background-color: var(--ncui-point);
        }

        &:focus{
            box-shadow: 0 0 0 0.2rem rgba(180, 180, 180, 0.5);
        }

        &:hover {
            &:before {
                opacity: 0.18;
            }
        }
    }

    &__btn-text{
        position: relative;
        z-index: 1;
    }
}

.dark-mode{
    .nc-modal {
        &__close svg{
            stroke: rgba(255, 255, 255, 0.83);
        }
        &__content {
            background-color: rgba(255, 255, 255, 0.95);
            // border: 1px solid rgba(255, 255, 255, 0.12);
        }
        &__title {
            color: rgba(0, 0, 0, 0.83);
        }
        &__body {
            color: rgba(0, 0, 0, 0.38);
        }
        &__btn {
            border: solid 1px rgba(255, 255, 255, 0.12);
            color: rgba(255, 255, 255, 0.6);
            &--pri {
                color: #fff;
                border: none;
            }
            &--sec{
                background-color: #202020;
            }
            &:focus{
                box-shadow: 0 0 0 0.2rem rgba(180, 180, 180, 0.5);
            }
        }
        &__footer {
            &.nc-modal--bottom &{
                &:not(:empty) {
                    border-top-color: rgba(255, 255, 255, 0.12);
                }
            }

        }
    }
}

@include bp(pc){
    .nc-modal {
        $self: &;
        &__dialog {
            width: 394px;
            max-height: 90%;

            #{$self}--bottom &{
                width: 652px;
                min-height: 56px; // 80px;
            }
        }
        &__content {
            max-height: calc(100vh - 8rem);

            #{$self}--bottom &{
                flex-direction: row;
                align-items: center;
            }
        }
        &__header {
            padding: 33px 40px 10px;

            #{$self}--bottom &{
                padding: 16px 16px;
            }
        }
        &__title {
            font-size: 18px;
            // letter-spacing: -0.7px;

            #{$self}--bottom &{
                font-size: 16px;
            }
        }
        &__body {
            padding: 0 40px;
            // letter-spacing: -0.7px;
        }
        &__footer {
            padding: 16px; // 25px 40px 40px;

            #{$self}--bottom &{
                &:not(:empty) {
                    border-top: 0;
                    padding: 0 11px;
                    margin-left: auto;
                    flex-shrink: 0;
                    width: 210px;
                    [dir=rtl] & {
                        width: auto;
                        margin-left: 0;
                        margin-right: auto;
                    }
                }

            }
        }
        &__btn {
            height: 40px;
            border-radius: 20px;
        }
    }
}
