// 혈맹원 목록 (가입신청 목록 포함)

.my-guild-member-list-wrap {
  min-height: 380px;
  margin-top: 6px;

  @include bp(pc) {
    margin-top: -35px;
  }
}

.guild-member-list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  @include clearfix;

  @include bp(pc) {
    margin: 0;

    &:after {
      width: 100%;
      height: 0;
      @include hide-text();
      border-bottom: 1px solid var(--ncui-gray008-hex);
    }
  }

  .guild-member-items {
    display: table;
    width: 100%;
    padding: 15px 16px 10px; //check check 다른 부분 LW
    position: relative;
    border-bottom: 1px solid var(--ncui-gray008-hex);
    &>a{ //check check 다른 부분 LW
      display: block;
    }
    @include bp(pc) {
      width: 50%;
      padding: 22px 24px;
      margin-bottom: -1px;
      float: left;
      min-height: 122px;
    }

    @include bp(max) {
      width: 33.333333%;
    }

    .thumb-wrap {
      display: table-cell;
      vertical-align: middle;
      padding-right: 12px;
      width: 60px;
      height: 60px;
      box-sizing: content-box;
      overflow: visible;
      [dir=rtl] & {
        padding: 0;
      }
      img {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        vertical-align: top;
      }
    }

    .member-info {
      display: table-cell;
      vertical-align: middle;
      font-size: 16px;
      color: #000;
      [dir=rtl] & {
        padding-right: 9px;
      }
    }

    .nickname {
      color: var(--ncui-black093);
      line-height: 1.08;
      word-break: break-all;
    }

    .class {
      &:before {
        @extend .guild-bar;
      }
      color: var(--ncui-black045);
    }

    .date {
      display: block;
      color: var(--ncui-black045);
      font-size: 14px;
    }

    .grade {
      position: absolute;
      right: 16px;
      top: 50%;
      display: inline-block;
      height: 22px;
      padding: 0 11px;
      margin-top: -11px;
      font-size: 13px;
      line-height: 20px;
      color: var(--ncui-point);
      border-radius: 11px;
      border: 1px solid var(--ncui-point);

      &-leader {
        color: #fff !important;
        background-color: var(--ncui-point);
      }

      @include bp(pc) {
        position: static;
        line-height: 18px;
        margin-top: -1px;
      }
    }

    .btns-wrap {
      padding-left: 10px;
      vertical-align: middle;
      text-align: right;
      @include ellipsis();
      display: table-cell;
      .btn-refusal {
        color: #8c919a;
        border-color: #8c919a;
      }
      @include bp(pc) {
        vertical-align: middle;
      }
    }

    // (20200102) 개편 관련 재선언 - 클래스명 줄내림, 마크업 변경됨
    // https://jira.ncsoft.com/browse/MGWT-1181
    .member-info-v2 {
      .nickname {
        font-size: 15px;
      }

      .grade {
        position: static;
        padding: 0;
        margin: 0;
        height: auto;
        font-size: 14px;
        background-color: transparent;
        border: 0 none;
        color: var(--ncui-black068);

        &::before {
          @extend .guild-bar;
          [dir=rtl] & {
            margin: 0 0 0 8px;
            padding: 0 0 0 6px;
          }
        }
      }

      .grade-leader {
        color: var(--ncui-point-text) !important;
      }

      .class {
        display: block;
        margin-top: 4px;
        font-size: 14px;
        color: var(--ncui-black045);
        
        &::before {
          display: none;
        }
      }
    }
  }

  // (20200102) 개편 관련 재선언 - 클래스명 줄내림, 마크업 변경됨
  // https://jira.ncsoft.com/browse/MGWT-1181
  .guild-member-items-v2 {
    @include bp(pc) {
      width: 33.333333%;
      padding-left: 30px;
      padding-right: 0;
    }
  }
}

// 혈맹 관리 > 혈맹원 목록 재선언 //check check 다른 부분 (트릭스터)
.guild-info-member {
  &.wating {
    @include bp(pc) {
      .guild-member-list .guild-member-items {
        width: 50%;
      }
      .guild-member-list .guild-member-items .member-info {
        margin-top: 18px;
      }
      .guild-member-list .guild-member-items .btns-wrap {
        margin-top: 4px;
      }
    }
  }
}