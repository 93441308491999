// 택스트
$text1 : rgba(255, 255, 255, 0.83);
$text2 : rgba(0, 0, 0, 0.93);
$text3 : rgba(255, 255, 255, 0.6);
$text4 : rgba(0, 0, 0, 0.68);
$text5 : rgba(0, 0, 0, 0.45);


// 배경
$bg1: rgba(0, 0, 0, 0.95);
$bg2: #fff;
$bg3: #121212;
$bg4 : rgba(0, 0, 0, 0.45);
// $bg5: #171717;
$bg6: rgba(34, 34, 34, 0.08);

// 보더
$border1:  rgba(255, 255, 255, 0.1);
$border2:  rgba(34, 34, 34, 0.12);
$border3:  rgba(34, 34, 34, 0.08);
$border4:  rgba(34, 34, 34, 0.2);



// 버튼
$btn1: #fff;
$btn2: rgba(34, 34, 34, 0.04);
$btn3: #171717;
$btn4: #fff;

// 스크롤바
$scroll-bar-thumb: #838499;

$svgDarkMode: "svg[data-dark-mode='light']";
$img-icon-check-off: "white-icon-check-off";
$img-icon-check-on-diabled: "white-icon-check-on-disabled";
$img-icon-check-on: "white-icon-check-on";

