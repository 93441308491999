// my loglist
// ===============================

.loglist {
  padding: 18px;
  @include bp(pc) {
    padding: 16px 0 0;
  }

  li {
    position: relative;
    margin-bottom: 4px;
    padding-left: 7px;
    line-height: 20px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
      width: 1px;
      height: 1px;
      background-color: #000;
    }
  }

  &-rule {
    font-size: 14px;

    &-info {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &-contents {
      height: 90px;
      margin-top: 20px;
      padding: 16px 20px;
      border: 1px solid var(--ncui-gray008);
      line-height: 20px;
      word-break: keep-all;
      color: var(--ncui-black068);

      @include bp(pc) {
        height: auto;
        padding: 30px;
      }

      .scroller {
        height: 100%;
        overflow-y: auto;
      }
    }
    &-items{
      color: var(--ncui-black093);
    }
  }

  // tablet 모드를 기준으로 레이아웃 변경
  &-table {
    width: 100%;
    margin-top: 30px;
    font-size: 15px;
    table-layout: fixed;
    border-collapse: collapse;

    // 여백 설정
    @include bp(tablet) {
      font-size: 16px;

      .date {
        width: percentage(calc(309/1260));
        padding-left: percentage(calc(70/1260));
      }

      .character {
        width: percentage(calc(349/1260));
      }

      .reason {
        width: percentage(calc(441/1260));
      }

      .detail {
        width: auto;
      }
    }

    .timeline {
      padding: 20px 10px 20px;

      @include bp(tablet) {
        padding-left: percentage(calc(70/1260));
        padding-top: 20px;
      }
    }

    pre {
      display: table-cell;
      padding: 0 10px;
      margin: 0;
      word-break: break-word;
      vertical-align: middle;
      text-align: left;
      white-space: pre-wrap;
      color: var(--ncui-point-text);
      @include bp(tablet) {
        padding: 0;
      }
    }

    thead {
      position: absolute;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;

      @include bp(tablet) {
        position: relative;
        clip: auto;
        height: auto;
        width: auto;
        overflow: auto;
      }

      th {
        border-top: 6px solid var(--ncui-gray008);
        text-align: left;
        font-weight: normal;
        color: var(--ncui-black045);
        line-height: 48px;
      }
    }

    tbody,
    tr,
    td {
      display: block;
      padding: 0;
      white-space: normal;
    }

    tr {
      @include bp(tablet) {
        display: table-row;
      }
    }

    td {
      vertical-align: middle;

      @include bp(tablet) {
        display: table-cell;
      }
    }

    tbody {
      @include bp(tablet) {
        display: table-row-group;
      }

      tr {
        margin-bottom: 1em;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.items {
          border-top: 5px solid var(--ncui-gray008);
          color: var(--ncui-black093);

          @include bp(tablet) {
            display: table-row;
            border-top: 1px solid var(--ncui-gray008);
          }
        }

        &.log {
          display: none;
          margin-top: -1em;
          html.dark-mode &{
            background: none;
          }
        }
      }

      td {
        min-height: 50px;
        position: relative;
        border-bottom: 1px solid var(--ncui-gray008);
        color: var(--ncui-black093);

        span {
          display: inline-block;
          margin: 13px 0 12px 125px;
          @include bp(tablet) {
            margin: 14px 40px 16px 0;
          }
        }
      }

      td[data-title]:before {
        content: attr(data-title);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 95px;
        padding: 13px 0 0 10px;
        margin-right: 20px;
        color: #747880;

        @include bp(tablet) {
          // Don’t show data-title labels
          content: none;
        }
      }
    }

    // 보기 버튼
    .btn-detail-view {
      position: relative;
      display: inline-block;
      margin: 13px 0 0 125px;
      padding: 0;
      border: 0;
      color: var(--ncui-black093);
      line-height: 1;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      text-decoration: none;
      background: 0 0;
      outline: none;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        right: -14px;
        top: 50%;
        width: 0;
        height: 0;
        margin-top: -2px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(var(--ncui-black068));
      }

      @include bp(tablet) {
        margin: 14px 0 16px 0;
      }
    }

    // 상세보기 펼치기 시
    .expand {
      .btn-detail-view:after {
        border-bottom: 5px solid var(var(--ncui-black068));
        border-top: 0;
      }

      & + .log {
        display: block;
      }

      @include bp(tablet) {
        & + .log {
          display: table-row;
        }
      }
    }
  }

  &-none {
    margin-top: 30px;
    padding: 55px 0 60px;
    border-top: 5px solid var(--ncui-gray004);
    border-bottom: 1px solid var(--ncui-gray004);
    text-align: center;
    color: var(--ncui-black068);
  }

  &-info {
    margin: 17px 0 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
    color: var(--ncui-black068);

    a {
      color: var(--ncui-black045);
      text-decoration: underline;
    }

    li:before {
      background-color: var(--ncui-black068);
    }
    .cs-link{
      text-decoration: underline;
    }
  }
}
