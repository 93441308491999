$ncuim-modal-content-padding: 18px;
$ncuim-modal-button-height: 52px;
$ncuim-modal-button-height-m: 48px;

$ncuim-modal-min-width: 352px;
$ncuim-modal-max-width: 412px;

$ncuim-modal-min-height: 184px;
$ncuim-modal-max-height: 619px;

$ncuim-modal-min-width-m: 280px;
$ncuim-modal-max-width-m: 328px;

$ncuim-modal-min-height-m: 181px;
