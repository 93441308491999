.ncui-chatbot {
  position: fixed;
  bottom: 58px;
  left: 10px;
  width: 54px;
  height: 54px;
  z-index: 10000;

  .page-main & {
    bottom: 10px;
  }
  &__bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('/img/md-chatbot/icon-chat.png');
    background-size: cover;
  }
  &__icon {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px;
    top: 8px;
    right: 10px;
    border-radius: 50%;
    background: var(--point-red, #da0000);
    display: none;

    &--active {
      display: block;
    }
  }
}
