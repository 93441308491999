
.g-cookie-confirm-toast-wrap,
.g-cookie-set-layer-wrap {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: keep-all;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  ul{
    padding:0;
  }
  a {text-decoration: none ; outline: none; color: inherit}
  a:hover, a:active {text-decoration: none ;}
  a:hover{cursor: pointer}

  input, textarea, button {
    outline: 0;
  }

  [class*=" is-show"] {
    display: block;
  }

  line-height: 1.38;
}

// theme(light)
// ===============================
@import "./theme/light-theme";

@import
"./feature/confirmToast",
"./feature/settingModal";

html.dark-mode {
    @import "./theme/dark-theme";

    @import
    "./feature/confirmToast",
    "./feature/settingModal";
}
