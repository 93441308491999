@mixin swiper-arrow() {
    background-image: url('/img/official/board/btn_arrow.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba(0, 0, 0, 0.3);
    background-size: 16px 31px;
    border-radius: 5px 0 0 5px;
    width: 54px;
    height: 65px;
    position: absolute;
    font-size: unset;
    font-family: unset;
    letter-spacing: unset;
    font-variant: unset;
    text-transform: unset;
    &::after {
        content: none;
    }
}
.board-top-banner-index {
    overflow: hidden;
    .board-top-banner {
        z-index: 0;
        position: relative;

        @include bp(pc) {
            margin-bottom: 30px;
        }

        .item {
            position: relative;
        }

        .link {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            img {
                width: auto;
                &.img-m {
                    height: calc(200px / 2);
                    @include bp(pc) {
                        display: none;
                    }
                }
                &.img-pc {
                    display: none;
                    @include bp(pc) {
                        display: block;
                        height: 130px;
                    }
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            width: 54px;
        }
        .swiper-button-prev {
            left: 0;
            &::after {
                content: none;
                @include bp(pc) {
                    content: '';
                    @include swiper-arrow();
                    transform: rotate(180deg);
                }
            }
        }
        .swiper-button-next {
            right: 0;
            &::after {
                content: none;
                @include bp(pc) {
                    content: '';
                    @include swiper-arrow();
                }
            }
        }
        .swiper-pagination {
            display: block;
            @include bp(pc) {
                display: none;
            }
            .swiper-pagination-bullet {
                width: 5px;
                height: 5px;
                margin: 0 2.5px;
                background: rgba(255, 255, 255, 0.38);
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                background: #fff;
                opacity: 1;
            }
        }
    }
}
.swiper-button-prev,
.swiper-button-next {
    &::after {
        content: none;
    }
}
html[dir='rtl'] {
    .swiper-button-prev,
    .swiper-button-next {
        &::after {
            content: none;
        }
    }
    .board-top-banner-index {
        .swiper-button-prev {
            left: unset;
            right: 0;
        }
        .swiper-button-next {
            right: unset;
            left: 0;
        }
        .swiper-rtl .swiper-button-next:after {
            content: none;
            @include bp(pc) {
                content: '';
                @include swiper-arrow();
                transform: rotate(180deg);
            }
        }
        .swiper-rtl .swiper-button-prev:after {
            content: none;
            @include bp(pc) {
                content: '';
                @include swiper-arrow();
                transform: unset;
            }
        }
    }
}
