


.g-cookie-confirm-dimmed {
  display: none;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
  z-index: 1;
  &.is-active{
    display: block;
  }
}
.g-cookie-confirm-toast-wrap{
  @media (orientation: landscape) {
    max-height: 90%;
  }
  &.is-active{
    display: flex;
  }
  max-height: 362px;
  line-height: 1.4;
  // letter-spacing: normal;
  z-index: 2;
  display: none;
  color: $text1;
  background: $bg1;

  width: calc(100% - 20px);
  margin: 10px;



  bottom: 0;
  padding:15px 12px 8px;
  position: fixed;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  font-size: 15px;
  &.raised {
    bottom: 50px;
  }
  @include bp(pc) {
    // margin: 20px;
    // width: 880px;
    margin:  0;
    width:  85%;
    left: 50%;
    transform: translateX(-50%);
  }
  .g-cookie-confirm-toast-top{
    // padding-bottom: 12px;
    margin-bottom: 12px;
    max-height: 262px;
    overflow: auto;
    @include bp(pc) {
      padding-bottom: 15px;
      max-height: none;
    }
    a{
      text-decoration: underline;
      font-weight: normal;
    }
  }
  .g-cookie-confirm-toast-bottom{
    .g-cookie-btn-wrap{
      // padding-top:8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      @include bp(pc) {
        // padding:0;
        flex-direction: row;
      }
      .g-cookie-btn-wrap-left{
        width: 100%;
        border-bottom: 1px solid $border1;
        padding-bottom: 14px;
        @include bp(pc) {
          width: auto;
          padding-bottom: 0;

        }
      }
      .g-cookie-btn-wrap-right{
        display: flex;
        // justify-content: space-around;
        justify-content: center;
        // padding-top:8px;
        flex-wrap: wrap;
        max-width: 100%;
        @include bp(pc) {
          padding-top:0

        }
        .g-cookie-btn{
          background-color: $btn1;
          color: $text2;
          margin-left: 8px;
          margin-top: 8px;
          max-width: 100%;

          html[dir=rtl] & {
            margin-left: 0;
            margin-right: 8px;
          }
        }
        // .g-cookie-btn-apply{
        //   background-color: $btn1;
        //   color: $text2;
        // }
        // .g-cookie-btn-deny{
        //   color: $text3;
        // }
      }
      .g-cookie-btn{
        padding :  8px 16px;
        font-size: 14px;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        // word-break: break-all;
        &.g-cookie-btn-config {
          padding:0;
          font-size: 14px;
          justify-content: start;
          align-items: center;
          @include bp(pc) {
          }
          svg{
            display: none;
            margin-right: 4px;
            html[dir=rtl] & {
              margin-right: 0;
              margin-left: 4px;
            }
          }
          #{$svgDarkMode} {
            display: block;
          }
        }
      }
    }
  }
}


