.id-verify{
    background-color: #151515e6;
    position: fixed;
    z-index: 20001;
    width: 100%;
    height: 170px;
    bottom: 0;
    right: 0;
    left: 0;
    color: #fff;

    @include purple-launcher {
        & {
            z-index: 9100006; // cnb z-index: 9100005
        }
    }

    &__wrapper{
        text-align: center;
        padding: 50px 0 0 0;
        font-size: 15px;
        color: #ffffff;
    }
    a {
        display: inline-block;
        text-decoration: none;
        min-width: 140px;
        padding: 0 10px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
        background-color: #64bcea;
        font-size: 16px;
        margin-top: 18px;
        color: #ffffff;
        transition-duration: 0.2s;
        &:hover {
            background-color: #2692cb;
        }
    }
    &__close{
        position: absolute;
        cursor: pointer;
        outline: 0;
        transition-duration: 0.17s;
        opacity: 0.5;
        border: 0;
        background: 0 0;
        top: 18px;
        right: 18px;
        width: 21px;
        height: 21px;
        &:after, &:before {
            content: ' ';
            position: absolute;
            left: 10px;
            height: 27px;
            width: 1px;
            background-color: #ffffff;
            top: -3px;
        }
        &:after{
            transform: rotate(45deg);
        }
        &:before{
            transform: rotate(-45deg);
        }
        &:hover {
            opacity: 1;
            transform: rotate(90deg);
        }
    }
    .blind{
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
    }
}


@media (min-width: 640px) {
    .id-verify{
        height: 88px;
        &__wrapper{
            text-align: left;
            padding: 19px 30px;
            line-height: 50px;
        }
        a{
            margin-top: 0;
            margin-right: 45px;
            float: right;
            text-align: center;
        }
        br{
            display: none;
        }
        &__close{
            top: 34px;
            right: 30px;
        }
    }
    
}