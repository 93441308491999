
body {
  font-family: 'Noto Sans', 'Noto Sans KR', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  & *{
    letter-spacing: 0 !important;
    font-family: 'Noto Sans', 'Noto Sans KR', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  }
  [data-service="lineagew"] & , [data-service="lineage2mru"] & , [data-service="lineage2mnaeu"] & , [data-service="plaync"] & , [data-service="puzzup"] & {
    font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans Thai', 'Noto Sans Arabic', "Apple LiGothic", -apple-system, BlinkMacSystemFont, Roboto, Arial, 'Microsoft JhengHei', 'Microsoft YaHei', sans-serif;
    * {
      font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans Thai', 'Noto Sans Arabic', "Apple LiGothic", -apple-system, BlinkMacSystemFont, Roboto, Arial, 'Microsoft JhengHei', 'Microsoft YaHei', sans-serif;
    }
  }
  [lang="ja"] & {
    font-family: 'Noto Sans','Noto Sans JP', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    * {
      font-family: 'Noto Sans','Noto Sans JP', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    }
  }
  [lang="zh"] & {
    font-family: 'Noto Sans', 'Microsoft JhengHei', 'Microsoft YaHei', 'Apple LiGothic', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    * {
      font-family: 'Noto Sans', 'Microsoft JhengHei', 'Microsoft YaHei', 'Apple LiGothic', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    }
  }
  [data-service="lineage2mnct"] & [data-service="plaync"][lang="zh"] & {
    font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', "Apple LiGothic", -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    * {
      font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', "Apple LiGothic", -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    }
  }
  [data-service="tl"][lang="en"] & {
    font-family: 'Noto Sans', 'Microsoft JhengHei', 'Microsoft YaHei', 'Apple LiGothic', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    & *{
      letter-spacing: 0 !important;
      font-family: 'Noto Sans', 'Microsoft JhengHei', 'Microsoft YaHei', 'Apple LiGothic', -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
    }
  }
}
