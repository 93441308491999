/* 상단 띠 배너 */
// 디자인 가이드 https://nckorea.atlassian.net/wiki/spaces/PDS/pages/315147174
// 폰트 컬러, 배경 컬러 1, 배경 컬러 2
$lineage : #FFE2B6 #AB8161 #76573E;
$lineage2 : #FFE5B8 #279EAC #047681;
$aion : #AFFFF0 #68A7C1 #437990;
$bns : #A6FFEF #7E8BF1 #5964BE;
$lineagem : #FFDDB1 #A7694F #784834;
$lineage2m : #FFDC8F #DC722F #B35317;
$h2 : #ABFFEC #4A97C9 #3972A8;
$tricksterm : #FFE3A9 #8D66EB #6E3EE0;
$bns2 : #FFE3A9 #B54838 #AA4536;
$h3 : #ABF3FF #2D6AB3 #0F53A3;
$lineagew : #FAE6CD #B09067 #846946;
$tl : #E3B8FF #8856A8 #5F3779;
$lovebeat : #FFFB90 #EB4196 #FB61AE;

$color-themes : (
    lineage : $lineage,
    lineage2 : $lineage2,
    aion: $aion,
    bns : $bns,
    lineagem : $lineagem,
    lineage2m : $lineage2m,
    lineage2mru : $lineage2m,
    lineage2mnaeu : $lineage2m,
    lineage2mncj : $lineage2m,
    lineage2mnct : $lineage2m,
    h2 : $h2,
    tricksterm : $tricksterm,
    bns2 : $bns2,
    h3 : $h3,
    lineagew : $lineagew,
    tl : $tl,
    lovebeat : $lovebeat
);


$scroll-bar-thumb: rgba(#838499, 0.30);

html.dark-mode {
  $scroll-bar-thumb: #838499;
}


/* 배너 공통 */
.nc-banner {
  position:fixed;
  display:none;
  z-index:20000;
  .blind {
    position:absolute;
    clip:rect(0 0 0 0);
    width:1px;
    height:1px;
    margin:-1px;
    overflow:hidden
  }
}

/* 플로팅 배너 */
.nc-banner[data-type=floating] {
  position:fixed;
  top: 50%;
  //bottom:10px;
  left:50%;
  width:214px;
  height:214px;
  margin-top: -107px;
  margin-left:-107px;
  opacity:0;
  visibility:hidden;
  transform:scale(.7);
  transition:transform .5s,opacity .5s,visibility .5s,-webkit-transform .5s;

  &.is-show {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }


 .nc-banner-link {
    display:block;
    width:100%;
    height:100%;
    background-position:center;
    background-repeat:no-repeat;
    background-size:contain
  }

  .nc-banner-close {
    position:absolute;
    top:141px;
    right:1px;
    width:40px;
    height:40px;
    padding:0;
    z-index:30000;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUiIGN5PSIxNSIgcj0iMTUiIGZpbGw9IiM0NDQ0NDQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC4wODI3IDlMMTUgMTQuMDgyN0w5LjkxODY1IDlMOSA5LjkxNzM1TDE0LjA4MjcgMTUuMDAxM0w5IDIwLjA4MjdMOS45MTg2NSAyMUwxNSAxNS45MTczTDIwLjA4MjcgMjFMMjEgMjAuMDgyM0wxNS45MTczIDE1LjAwMTNMMjEgOS45MTczNUwyMC4wODI3IDlaIiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPC9zdmc+);
    background-size: contain;
    border-radius:50%;
    cursor: pointer;
    border: none;
    [dir=rtl] & {
      right: auto;
      left: 1px;
    }
  }

  &.is-bottom-floating {
    top: auto;
    bottom: 10px;
    .nc-banner-close {
      top: auto;
      bottom: 166px;
      right: 18px;
      width: 30px;
      height: 30px;
    }
  }
}

@include bp(pc) {
  .nc-banner[data-type=floating] {
    &.is-bottom-floating {
      top: auto;
      left: auto;
      bottom: 10px;
      right: 10px;
    }
  }
}


/* 토스트배너 */
.nc-banner[data-type=toast] {
  display: block;
  right:50%;
  bottom:0;
  width:375px;
  max-width:100%;
  height:230px;
  opacity: 0;
  visibility:visible;
  overflow:hidden;
  transform:translateX(50%) translateY(200%);
  transition:transform .6s cubic-bezier(.55,0,1,1),-webkit-transform .6s cubic-bezier(.55,0,1,1);

  &.is-show {
    //display: block;
    //visibility: visible;
    transform:translateX(50%) translateY(0);
    transition:transform .6s cubic-bezier(0,1.11,1,.99);
    opacity:1
  }

  .nc-banner-inner {
    position:absolute;
    top:50%;
    left:0;
    width:100%;
    margin-top:45px;
    transform:translateY(-50%);
  }

  .nc-banner-link {
    display:block;
    width:100%;
    height:100%;
    text-align:center;
    background-position:center bottom;
    background-repeat:no-repeat;
    background-size: contain;
   }

  .nc-banner-tag {
    display:inline-block;
    margin:0 0 7px;
    font-size:14px;
    font-weight: normal;
    color: #fff68B;
  }
  .nc-banner-title-main {
    display:block;
    margin:0 0 7px;
    font-size:26px;
    font-weight: normal;
    color:#fff;
    text-shadow: 0 2px 0 rgba(#000, 0.2);
   }
  .nc-banner-title-sub {
    display:block;
    margin:0 0 12px;
    font-size:13px;
    color:#fff;
    font-style:normal;
    opacity: 0.83;
  }
  .nc-banner-close {
    position:absolute;
    bottom:120px;
    right:14px;
    width:20px;
    height:20px;
    padding:0;
    cursor: pointer;
    background: transparent;
    border: 0;
    [dir=rtl] & {
      right: auto;
      left:14px;
    }
    &:before {
      position:absolute;
      top:50%;
      left:50%;
      width:26px;
      height:1px;
      border-radius:2px;
      background-color:#fff;
      transform:translate(-50%,-50%) rotateZ(45deg);
      content:''
    }
    &:after {
      position:absolute;
      top:50%;
      left:50%;
      width:26px;
      height:1px;
      border-radius:2px;
      background-color:#fff;
      transform:translate(-50%,-50%) rotateZ(-45deg);
      content:''
    }
  }
}
@include bp(tablet) {
  .nc-banner[data-type=toast] {
    right:0;
    transform: translateX(0) translateY(200%);

    &.is-show {
      right:0;
      transform:translateX(0) translateY(0);
    }
  }
}

/* 상단 띠배너 */
.nc-banner[data-type=top] {
  position: relative;
  width: 100%;
  height: 56px;
  overflow: hidden;

  &.is-show {
    display: block;
  }

  .nc-banner-inner {
    position: relative;
    height: 100%;
    text-align: center;
    //background-image: radial-gradient(circle at 50% 50%, #ab8161, #76573e 99%);
  }

  .nc-banner-link {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    // line-height: 1.2;
  }
  .nc-banner-title {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    // letter-spacing: -0.42px;
    color: rgba(255, 255, 255, 0.83);
    //margin-bottom: 5px;
    max-width: 70%;
    strong {
      font-weight: normal;
      [dir=rtl] & {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
  .nc-banner-date {
    height: 56px;
    line-height: 52px;
    margin-left: 8px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
    // font-family: HelveticaNeue;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    text-decoration: none;
    // letter-spacing: -0.42px;
    text-align: center;
    [dir=rtl] & {
      margin-right: 10px;
      margin-left: 0;
    }
  }
  .banner-date-num {
    font-style: italic;
  }
  .nc-banner-button {
    display: inline-block;
    height: 40px;
    line-height: 38px;
    margin: 0 0 0 10px;
    padding: 0 16px 0 16px;
    border-radius: 20px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 16px;
    font-weight: normal;
    // letter-spacing: -0.75px;
    [dir=rtl] & {
      margin-right: 10px;
      margin-left: 0;
    }
  }
  .nc-banner-gradation-left {
    left: 0;
  }
  .nc-banner-gradation-right {
    right: 0;
  }
  .nc-banner-gradation-left,
  .nc-banner-gradation-right {
    position: absolute;
    top: 0;
    span{
      display: block;
      width: 56px;
      height: 56px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'%3E%3Cg fill='none' fill-rule='evenodd' opacity='.3'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23000' d='M28 28L56 56 0 56z' opacity='.2' transform='translate(-112) translate(112) matrix(-1 0 0 1 56 0)'/%3E%3Cpath fill='%23FFF' d='M28 0L56 28 0 28z' opacity='.1' transform='translate(-112) translate(112) rotate(-180 28 14)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      //opacity: 0.4;
      opacity: 0.7;
    }

  }
}
@include bp(pc) {
  .nc-banner[data-type=top] {
    .nc-banner-gradation-left,
    .nc-banner-gradation-right {
      span {
        //opacity: 0.7;
        opacity: 1;
      }
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 56px;
        height: 56px;
        top: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'%3E%3Cg fill='none' fill-rule='evenodd' opacity='.3'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23000' d='M28 28L56 56 0 56z' opacity='.2' transform='translate(-112) translate(112) matrix(-1 0 0 1 56 0)'/%3E%3Cpath fill='%23FFF' d='M28 0L56 28 0 28z' opacity='.1' transform='translate(-112) translate(112) rotate(-180 28 14)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
    .nc-banner-gradation-left {

      &:before {
        left: 56px;
        //opacity: 0.4;
        opacity: 0.7;
      }
      &:after {
        left: 112px;
        //opacity: 0.3;
        opacity: 0.6;
      }
    }
    .nc-banner-gradation-right {
      &:before {
        right: 56px;
        //opacity: 0.4;
        opacity: 0.7;
      }
      &:after {
        right: 112px;
        //opacity: 0.3;
        opacity: 0.6;
      }
    }
  }
}


/* 모달 공통 */
#NC-banner-modal,
#NC-banner-movie {
  .nc-modal__header {
    display: none;
  }
  .nc-modal__body{
    padding: 0;
  }
  .nc-modal__content {
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }
  .nc-modal__footer {
    width: 100%;
    padding: 0;
  }
  .nc-modal__btn {
    height: 48px;
    width: 50%;
    padding:0;
    border:0;
    border-radius: 0;
    background: #202020;
    color: rgba(255, 255, 255, 0.6);
    float: left;
    [dir=rtl] & {
      float: right;
    }
    &:before {
      display: none !important;
    }
    &:focus {
      box-shadow: none;
    }
    .nc-modal__btn-text {
      display: block;
      margin-top: -2px;
    }
  }
  .nc-modal__btn--pri {
    margin: 0;
    border-right: 1px solid rgba(#FFF, 0.2);
    [dir=rtl] & {
      border-right: none;
      border-left: 1px solid rgba(#FFF, 0.2);
    }
  }
}

@include bp(pc) {
  #NC-banner-modal,
  #NC-banner-movie {
    .nc-modal__footer {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -39px;
      text-align: right;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .nc-modal__btn {
      background: transparent;
      width: auto;
      float: none;
      vertical-align: middle;
      &:before {
        background-color: transparent;
      }
    }
    .nc-modal__btn--pri {
      height: 21px;
      font-size: 15px;
      color: rgba(#FFF, 0.6);
      margin: 2px 20px 1px 0;
      padding: 0 18px;
      [dir=rtl] & {
        margin-left: 20px;
        margin-right: 0;
      }
      &:hover {
        background: transparent;
      }
    }
    .nc-modal__btn--sec {
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-opacity='.6' fill-rule='evenodd'%3E%3Cg fill='%23FFF'%3E%3Cpath d='M22.899 0L12 10.899 1.102 0 0 1.101 10.899 12.002 0 22.899 1.102 24 12 13.101 22.899 24 24 22.899 13.101 12.002 24 1.101z' transform='translate(-1348 -108) translate(1348 108)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      text-indent: -999px;
      font-size:0;
      overflow: hidden;
    }
  }
}


/* 모달 배너 - 이미지 */
#NC-banner-modal {

  /* 모달 배너 - 이미지 - 스크롤 */
  &.nc-modal--scroll{
    .nc-modal__body {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(894px /2);

      .nc-banner-modal-pc,
      .nc-banner-modal-mobile {
        img {
          max-height: none;
          max-width: none;
          width: 100%;
        }
      }

      &::-webkit-scrollbar-thumb{
        background-color: rgba(0, 0, 0, .4);
      }

      &::-webkit-scrollbar{
        width: 6px;
        background-color: white;
      }

      body.IOS & {
        background-color: black;

        &::-webkit-scrollbar-thumb{
          background-color: transparent;
        }

        &::-webkit-scrollbar{
          width: 0;
          background-color: transparent;
        }
      }
    }
  }
  .nc-modal__dialog {
    width: 100%;
    max-width: calc(656px / 2 + 16px);
    max-height: calc(894px /2);
    padding: 0 8px;
  }
  .nc-modal__body {
    overflow-y: hidden;

  }
  .nc-banner-modal-mobile {
    //width: 328px;
    //height: 447px;
    img {
      width: 100%;
      height: auto;
      max-width: calc(656px / 2);
      max-height: calc(894px /2);
      vertical-align: top;
    }
  }
  .nc-banner-modal-pc {
    display: none;
    //width: 840px;
    //height: 724px;
    img {
      width: 100%;
      height: auto;
      max-width: 1180px;
      max-height: 700px;
      vertical-align: top;
    }
  }

  &.imgBannerVertical {
    .nc-modal__dialog {
      width: auto;
    }
    .nc-banner-modal-mobile {
      img {
        width: auto;
        height: 100%;
      }
    }
    .nc-banner-modal-pc {
      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &.imgBannerFullcover {
    .nc-modal__dialog {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
@include bp(pc) {
  #NC-banner-modal {
    &.nc-modal--scroll {
      .nc-modal__body {
        max-height: 700px;
      }
    }
    .nc-modal__dialog {
      max-width: (1180px + 32px);
      max-height: 700px;
      padding: 0 16px;
    }
    .nc-modal__footer {
      padding: 0 16px;
    }
    .nc-banner-modal-pc {
      display: block;
    }
    .nc-banner-modal-mobile {
      display: none;
    }
  }
}

/* 모달 배너 - 영상 */
#NC-banner-movie {
  .nc-modal__dialog {
    width: auto;
  }
  .nc-modal__body {
    width: 90vw;
    height: 50.625vw;
    max-width: calc(65vh * 16 / 9);
    max-height: 65vh;
    background-color: #000;
    iframe {
      vertical-align: top;
    }
    .video {
      vertical-align: top;
      width: 100%;
      height: 100%;
    }
  }
}
@include bp(pc) {
  #NC-banner-movie {
    .nc-modal__btn--pri {
    }
  }
}

/* 상단 띠 배너 */
#ncTopBanner {
  @each $item, $color in $color-themes {
      [data-service=#{$item}] & {
        .nc-banner-title strong,
        .nc-banner-date,
        .nc-banner-button{
          color: nth($color,1) !important;
        }
        .nc-banner-inner{
            background-image: radial-gradient(circle at 50% 50%, nth($color,2), nth($color,3) 99%);
        }
      }
  }
}
