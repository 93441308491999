.ncui-share {
    * {
        margin: 0;
        padding: 0;
    }
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 9999999;
    &.is-open {
        display: block;
    }
    &-wrapper {
        position: relative;
        width: 350px;
        padding: 30px 0 26px;
        border-radius: 4px;
        border: 1px solid var(--ncui-gray012);
        background-color: var(--ncui-bg);
        .dark-mode & {
            background-color: var(--ncui-gray008-hex);
        }
    }
    &-dim {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 9100024; // cnb z-index 값
        background-color: rgba(#000, 0.5);
        &.is-open {
            display: block;
        }
    }
    &-close {
        position: absolute;
        top: 10px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        // @include hide-text();
        text-align: left;
        @include rtl('text-indent', -9999em, 9999em);
        white-space: nowrap;
        overflow: hidden;

        @include rtl(right, 10px, auto);
        @include rtl(left, auto, 10px);
        background-color: transparent;
        border: none;
        &:before,
        &:after {
            position: absolute;
            right: 10px;
            top: 2px;
            content: '';
            width: 1px;
            height: 20px;
            background-color: var(--ncui-black068);
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &-title {
        margin-bottom: 6px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: var(--ncui-black093);
    }
    &-list {
        max-width: 226px;
        margin: auto;
        button {
            // @include hide-text();
            text-align: left;
            @include rtl('text-indent', -9999em, 9999em);
            white-space: nowrap;
            overflow: hidden;

            position: relative;
            display: inline-block;
            width: 55px;
            height: 55px;
            outline: none;
            overflow: hidden;
            margin: 8px 10px;
            padding: 0;
            border: 1px solid var(--ncui-gray012);
            vertical-align: top;
            line-height: 0;
            border-radius: 50%;
            cursor: pointer;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            // @include rtl('transform', scaleX(1), scaleX(-1));
        }
        [data-name='facebook'] {
            background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2732%27 height=%2732%27%3E%3Cpath fill=%27%23386DB4%27 fill-rule=%27evenodd%27 d=%27M31 16.09C31 7.758 24.285 1 16 1S1 7.756 1 16.09C1 23.625 6.487 29.868 13.658 31V20.452h-3.81V16.09h3.81v-3.325c0-3.782 2.24-5.87 5.665-5.87 1.642 0 3.357.293 3.357.293v3.715h-1.89c-1.864 0-2.445 1.163-2.445 2.356v2.83h4.161l-.665 4.363h-3.496V31C25.514 29.867 31 23.624 31 16.091%27/%3E%3C/svg%3E');
        }
        [data-name='twitter'] {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5687 13.8561L28.62 2H26.2382L17.5106 12.2945L10.5399 2H2.5L13.0411 17.5671L2.5 30H4.88198L14.0985 19.1287L21.4601 30H29.5L18.5681 13.8561H18.5687ZM15.3062 17.7042L14.2382 16.1541L5.74025 3.81955H9.39884L16.2568 13.7739L17.3248 15.324L26.2393 28.2632H22.5807L15.3062 17.7048V17.7042Z' fill='black'/%3E%3C/svg%3E%0A");
            .dark-mode & {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5687 13.8561L28.62 2H26.2382L17.5106 12.2945L10.5399 2H2.5L13.0411 17.5671L2.5 30H4.88198L14.0985 19.1287L21.4601 30H29.5L18.5681 13.8561H18.5687ZM15.3062 17.7042L14.2382 16.1541L5.74025 3.81955H9.39884L16.2568 13.7739L17.3248 15.324L26.2393 28.2632H22.5807L15.3062 17.7048V17.7042Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
        [data-name='copy'] {
            background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2732%27 height=%2732%27%3E%3Cpath fill-opacity=%27.68%27 fill-rule=%27evenodd%27 d=%27M6.287 22c2.998 0 4.498-1.648 4.498-4.946V10h-1.31v7.278c0 2.363-1.01 3.543-3.026 3.543-2.097 0-3.146-1.223-3.146-3.671V10H2v7.238C2 20.413 3.43 22 6.287 22zm8.799-.2v-5.051h1.181c.48 0 .89.112 1.23.337.34.225.723.689 1.148 1.395l1.998 3.319h1.569l-2.224-3.543c-.405-.653-.716-1.098-.934-1.335a2.382 2.382 0 0 0-.83-.573v-.033c1.758-.486 2.637-1.544 2.637-3.173 0-.973-.323-1.741-.967-2.301-.644-.562-1.513-.842-2.608-.842h-3.502v11.8h1.302zm1.95-6.253h-1.95V11.21h1.965c.788 0 1.39.18 1.808.537.418.358.628.858.628 1.499 0 .685-.226 1.239-.676 1.664-.451.425-1.042.637-1.775.637zM30 21.8v-1.187h-4.812V10h-1.302v11.8H30z%27/%3E%3C/svg%3E');
            .dark-mode & {
                background-image: url("data:image/svg+xml,%3Csvg width='28' height='12' viewBox='0 0 28 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.28697 12C7.28452 12 8.78481 10.352 8.78481 7.054V0H7.4742V7.278C7.4742 9.641 6.46526 10.821 4.44941 10.821C2.35183 10.821 1.30254 9.598 1.30254 7.15V0H0V7.238C0 10.413 1.42966 12 4.28697 12ZM13.0859 11.8V6.749H14.2674C14.7476 6.749 15.1573 6.861 15.4973 7.086C15.8363 7.311 16.2197 7.775 16.6454 8.481L18.6431 11.8H20.212L17.9883 8.257C17.5827 7.604 17.272 7.159 17.0541 6.922C16.8351 6.684 16.5597 6.493 16.2247 6.349V6.316C17.9823 5.83 18.8611 4.772 18.8611 3.143C18.8611 2.17 18.5382 1.402 17.8945 0.842C17.2498 0.28 16.3811 0 15.2864 0H11.7844V11.8H13.0859ZM15.0362 5.547H13.0859V1.21H15.0513C15.8393 1.21 16.4416 1.39 16.8593 1.747C17.277 2.105 17.4869 2.605 17.4869 3.246C17.4869 3.931 17.2609 4.485 16.8109 4.91C16.3599 5.335 15.7687 5.547 15.0362 5.547ZM28 11.8V10.613H23.1884V0H21.8858V11.8H28Z' fill='white' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &-item-wrap{
        display: flex;
        justify-content: start;
        &:first-child{
            justify-content: center;
        }
    }
}

.ncuim-container.share-toast {
    .ncuim-wrapper {
      padding-bottom: 16px !important;
    }
    .ncuim-content {
      padding-top: 0 !important;
    }
}


