.guild-admin-prev-wrap {
  height: 50px;
  padding: 13px 0 0 14px;
  border-bottom: solid 1px var(--ncui-gray012);
  @include bp(pc) {
    height: 60px;
    margin-top: -45px;
    padding-top: 17px;
    padding-left: 0;
    border-color: var(--ncui-gray012);
  }

  a { //check check 다른 부분
    padding-left: 20px;
    font-size: 15px;
    color: var(--ncui-black100);
    line-height: 16px;

    @include bp(pc) {
      height: 19px;
      font-size: 16px;
      line-height: 19px;
    }

  }
}

.guild-info-header-wrap {
  width: 100%;

  h1 {
    display: none;
  }
}

.guild-info-header {
  position: relative;
  min-height: 140px;
  padding: 30px 15px 30px 15px;
  text-align: center;
  border-bottom: 1px solid var(--ncui-gray008); //check check 다른 부분

  @include bp(pc) {
    padding-top: 48px;
    padding-bottom: 36px;
    border-bottom: 0 none;
  }

  .guild-title {
    margin: 0;
    font-size: 17px;
    font-weight: normal;

    @include bp(pc) {
      font-size: 16px;
    }

    .server {
      line-height: 1;
      color: var(--ncui-black093); //check check 다른 부분
    }

    .guild {
      line-height: 1;
      color: var(--ncui-point);
      &:before {
        @extend .guild-bar;
      }
    }

    .person {
      color: var(--ncui-black045); //check check 다른 부분

      &:before {
        @extend .guild-bar;
      }
    }
  }

  .intro {
    margin-top: 7px;
    color: var(--ncui-black045); //check check 다른 부분
    font-size: 14px;

    @include bp(pc) {
      margin-top: 7px;
      font-size: 14px;
    }
  }

  .btn-wrap {
    margin-top: 15px;

    .btn-guild-modify-intro {
      @extend .btn-guild-type2;
      border: 0 none;
    }

    .btn-guild-exit {
      @extend .btn-guild-type1;
    }

    @include bp(pc) {
      margin-top: 20px;
    }
  }
}

// 혈맹원목록 탭 재선언
.tab-guild-member {
  margin-top: 0;

  .depth3-list {
    width: 100%;
    padding-right: 0;
  }
  .depth3-list-items {
    width: 50%;
  }

  .count {
    font-style: normal;
  }

  @include bp(pc) {
    margin-bottom: 18px;
  }
}

// 즉시가입일때 탭 대신
.guild-member-wating {
  height: 0;

  @include bp(pc) {
    margin-bottom: 0;
    border-bottom: 5px solid #f4f5f7;
  }
}

.guild-info-contents {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  overflow: hidden;
}

// 혈맹 관리 > 혈맹원 목록 재선언 //check check 다른 부분
.guild-info-member {
  display: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.wating { //check check 다른 부분
    @include bp(pc) {
      .guild-member-list .guild-member-items {
        width: 50%;
      }
      .guild-member-list .guild-member-items .member-info {
        margin-top: 18px;
      }
      .guild-member-list .guild-member-items .btns-wrap {
        margin-top: 4px;
      }
    }
  }

  h2 {
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    @include hide-text();
  }

  .none {
    padding: 50px 0;
    font-size: 16px;
    color: #8c919a;
    text-align: center;
  }

  &.is-selected {
    display: block;
  }

  .btns-wrap { //check check 다른 부분
    padding-left: 10px;
    vertical-align: middle;
    text-align: right;
    @include ellipsis();
    display: table-cell;

    @include bp(pc) {
      vertical-align: middle;
    }
  }

  .leader,
  [class^="btn-"] {
    display: inline-block;
    min-width: 44px;
    height: 22px;
    padding: 0 10px;
    line-height: 18px;
    font-size: 13px;
    color: #fff; //check check 다른 부분
    font-weight: normal;
    text-align: center;
    border-radius: 11px;
    @include ellipsis();
    border: solid 1px var(--ncui-point); //check check 다른 부분
    background-color: var(--ncui-point); //check check 다른 부분

    @include bp(pc) {
      min-width: 54px;
      height: 30px;
      padding: 0 12px;
      line-height: 28px;
      border-radius: 30px;
    }
  }

  .leader { //check check 다른 부분
    cursor: default;

    @include bp(pc) {
      padding: 0 10px;
    }
  }

  .btn-refusal{ //check check 다른 부분
    border: solid 1px var(--ncui-gray012);
    color: var(--ncui-black068);
    background-color: transparent;
  }

}

// admin layer 컬러값 관련 재선언 //check check 다른 부분
.layer-preorder-guild-admin {
  .layer-container{
    border-color: var(--ncui-gray012);
    background-color: var(--ncui-bg);
    .layer-btn-close{
      //background: url($layerBtnCloseUrl);
      background-size: cover;
    }
  }

  .pre-guild-admin-wrap{ //check check 다른 부분
 
    .create-tr{
      border-bottom: 1px solid var(--ncui-gray012);
      .create-th, .create-td{
        color: var(--ncui-black068);
      }
      &.create-tr-title.create-tr-title-modify {
        strong.create-th{
          color: var(--ncui-point);
        }
      }
      &.create-tr-server{
        .create-th{
          color: var(--ncui-point)
        }
      }
    }

    .wrap-guild-tag{
      border: 1px solid var(--ncui-gray012);
      .tag-list{
        .tag-items {
          background-color: var(--ncui-gray010);
          color:var(--ncui-black068);
          &.is-active{
            background-color: var(--ncui-gray020);
          }
        }
      } 

      .new-tag{
        input[type=text], input[type=text]::-webkit-input-placeholder{
          color: var(--ncui-black045)
        }
      }
    }

    textarea{
      background-color: var(--ncui-bg);
      border: 1px solid var(--ncui-gray012);
      color: var(--ncui-black093);
    }

    .pre-guild-radio{
      .custombox:before {
        border-color: var(--ncui-point);
      }
      .custombox:after {
        border-color: var(--ncui-point);
        background-color: var(--ncui-point);
      }
      .join-type{
        color: var(--ncui-black093);
      }
    }
    .btn-guildinfo-modify {
      background-color: var(--ncui-point);
    }
  }
}