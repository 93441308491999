// swipe scss

$tab-height-m: 49px;
$tab-height-pc: 54px;

.tab-wrap-contents,
.depth3-tab,
.depth4-tab {
    * {
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
    }
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.tab-swipe.parent-tab .tab-swipe__wrap {
    padding: 0;
}
.tab-swipe.parent-tab .tab-swipe__wrap,
.depth3-list-wrap {
    position: relative;
    height: auto;
    overflow: hidden;
    background-color: var(--ncui-point);

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0; // bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    @include bp(pc) {
        margin-top: 0;
        margin-bottom: 35px;
        background-color: transparent !important;
        &:before {
            display: none;
        }
    }

    .scroller {
        position: relative;
        z-index: 1;
        height: 100%;
        @include bp(pc) {
            width: 100% !important;
        }
    }

    .tab-swipe__list,
    .depth3-list {
        display: flex !important;
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        user-select: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        margin-top: 0px;
        @include clearfix;

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }

        @include bp(pc) {
            width: 100%;
            padding: 0;
            border-bottom: none;
            &:after {
                display: none;
            }
        }
    }

    .tab-swipe__list-items,
    .depth3-list-items {
        position: relative;
        z-index: 1;
        display: inline-block;
        height: $tab-height-m;
        font-size: 17px;
        white-space: nowrap;
        text-align: center;
        vertical-align: top;
        @include bp(pc) {
            width: 100%;
            font-size: 16px;
            height: $tab-height-pc + 4;
        }
        &:first-child {
            margin-left: 10px;
        }

        a {
            display: flex;
            height: 100%;
            margin: 0 6px;
            padding: 0 4px;
            // line-height: $tab-height-m - 1;
            color: rgba(255, 255, 255, 0.6);
            align-items: center;

            img {
                width: auto;
                &.img-m {
                    height: $tab-height-m - 19;
                    @include bp(pc) {
                        display: none;
                    }
                }
                &.img-pc {
                    display: none;
                    @include bp(pc) {
                        display: block;
                        height: $tab-height-pc;
                    }
                }
            }

            @include bp(pc) {
                display: inline-flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                white-space: normal;
                line-height: unset;
                margin: 0;
                padding: 0 10px;
                color: var(--ncui-black068);
            }
        }

        &.selected {
            font-weight: 400;

            a {
                color: var(--ncui-ui-white);
                border-bottom: 2px solid;
                border-color: rgba(255, 255, 255, 0.83);
                border-radius: unset;

                @include bp(pc) {
                    color: var(--ncui-point-text);
                    border-bottom: 0 none;
                    margin-top: -1px;
                }
            }
        }

        @include bp(pc) {
            height: $tab-height-pc;
            display: table-cell;
            font-weight: normal;
            box-sizing: border-box;
            background-color: var(--ncui-gray004);
            border-bottom: 1px solid var(--ncui-gray012);
            border-left: 1px solid var(--ncui-gray012);
            border-top: 1px solid var(--ncui-gray012);
            &:first-child {
                margin: 0;
            }
            &:last-child {
                border-right: 1px solid var(--ncui-gray012);
            }
            &.selected {
                background-color: var(--ncui-bg);
                border-bottom: none;
                border-top: 2px solid var(--ncui-point);
                a {
                    color: var(--ncui-point-text);
                }
            }
        }
    }
}

// 카테고리
.category-wrap {
    padding: 7px 16px 0 16px;
    border-bottom: 5px solid #f4f5f7;

    @include bp(pc) {
        max-width: 1260px;
        margin: 0 auto;
        padding-top: 0;
    }

    @include bp(max) {
        padding: 0;
    }
}

.tab-swipe,
.depth4-tab {
    @include bp(pc) {
        margin-bottom: 35px;
    }

    .tab-swipe__wrap {
        display: block !important;
    }

    .tab-swipe__wrap,
    .depth4-list-wrap {
        position: relative;
        display: none;
        padding: 0;
        height: 49px;
        overflow: hidden;
        border-bottom: 1px solid var(--ncui-gray012);

        &.is-show {
            display: block;
        }
        @include bp(pc) {
            height: 56px;
            margin-top: -35px;
            padding: 0 34px;
            background-color: var(--ncui-bg);
            border: 1px solid var(--ncui-gray012);
            border-top: none;
        }

        .tab-swipe__list,
        .depth4-list {
            display: flex;
            height: 100%;
            padding: 0 12px 0 0;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            user-select: none;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera*/
            }

            @include bp(pc) {
                margin-top: 3px;
                padding: 0;
            }

            &-items {
                display: inline-flex;
                height: 48px;
                align-items: center;
                vertical-align: top;
                &:first-child {
                    margin-left: 6px;
                }
                &.selected {
                    & > a {
                        color: var(--ncui-ui-white);
                        margin: 0 10px;
                        padding: 3px 12px;
                        border-radius: 14px;
                        background-color: var(--ncui-point);
                        @include bp(pc) {
                            margin: 0;
                            padding: 3px 10px;
                            color: var(--ncui-point-text);
                            background-color: transparent;
                        }
                    }
                }

                @include bp(pc) {
                    &:first-child {
                        margin: 0px;
                    }
                    & + .depth4-list-items {
                        position: relative;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 18px;
                            left: 0;
                            width: 1px;
                            height: 12px;
                            background-color: var(--ncui-gray012);
                        }
                    }
                }
                & > a {
                    display: flex;
                    align-items: center;
                    height: 28px;
                    padding: 3px 10px;
                    font-size: 16px;
                    color: var(--ncui-black068);
                    img {
                        width: auto;
                        &.img-m {
                            height: $tab-height-m - 19;
                            @include bp(pc) {
                                display: none;
                            }
                        }
                        &.img-pc {
                            display: none;
                            @include bp(pc) {
                                display: block;
                                height: $tab-height-pc;
                            }
                        }
                    }
                    @include bp(pc) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

html[dir='rtl'] {
    .parent-list,
    .depth3-list {
        .parent-list-items,
        .depth3-list-items {
            &:first-child {
                margin-left: 0;
                margin-right: 10px;
            }
            @include bp(pc) {
                &:first-child {
                    margin: 0;
                    border-right: 1px solid var(--ncui-gray012);
                }
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
    .tab-swipe,
    .depth4-list {
        padding: 0;
        .tab-swipe__list-items,
        .depth4-list-items {
            &:first-child {
                margin-left: 0;
                margin-right: 10px;
            }
            @include bp(pc) {
                &:first-child {
                    margin: 0;
                }
                & + .depth4-list-items {
                    &:after {
                        left: unset;
                        right: 0;
                    }
                }
            }
        }
    }
}
/* end 4depth */
