// Breakpoint
$hidpi: "min-resolution: 1.5dppx";

$breakpoints: (
  popup: "min-width: 500px",
  landscape: "min-width: 667px",
  tablet: "min-width: 640px",
  pc: "min-width: 960px",
  max: "min-width:1260px",
  notPC: "max-width: 959px"
);

$maxWidth : 1180px;
