// layout_container & contents
// ===============================

// All browsers
button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

// layout
//-------------------------
body {
  background-color: var(--ncui-bg);
}

.container {
  color: var(--ncui-black100);
  line-height: 1.4;
  @include bp(pc) {
    line-height: 1.5;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

.section-contents {
  @include bp(notPC) {
    @at-root .nc-cnb.is-2depth ~ .container & {
        padding-top: 49px;
    }
  }

  @include bp(pc) {
    padding: 55px 40px 0;
    min-height: 700px;
  }
}

.wrap-contents {
  max-width: 1180px;
  margin: 0 auto;
}

.max-width-container {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}

// google logger height 재선언
iframe[name="google_conversion_frame"] {
  display: none;
}

// 앱일때 처리/퍼플존/커뮤니티 SDK 웹뷰일 때 추가
body.ncMob,
body.ncPurpleZone,
body.ncCommunitySDK {
  background-color: var(--ncui-bg);

  #nc-cnb {
    display: none;
  }

  .wrap-header{
    display: none;
  }

  .container {
    margin-top: 0;
  }

  .nc-banner{
    display: none;
  }

  @include bp(pc) {
    .section-contents {
      padding-top: 50px !important;
      padding-bottom: 50px;
    }
  }
}

body.ncPurpleZone {
  #nc-cnb {
    display: none !important;
  }
}

// 퍼플일 때 처리
body.ncPurple {
  // 퍼플 런처일 때 적용
  @include bp(pc) {
    overflow: overlay;

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(131, 137, 153, 0.3);
        width: 4px;
        background-clip: padding-box;
        border: 4px solid transparent;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-button,
    ::-webkit-resizer {
        display: none;
    }
  }

  #nc-cnb {
    display: block;
  }

  .wrap-header {
    display: none !important;
  }

  .nc-banner{
    display: none;
  }

  .smartbanner {
    display: none;
  }

  .container {
    padding-bottom: 64px;
  }

  &.page-search {
    .section-contents {
      @include bp(pc) {
        padding: 0;
      }
    }

    .wrap-search-header {
      @include bp(pc) {
        margin: 0 0 55px !important;
      }
    }
  }

  @include bp(pc) {
    .section-contents {
      padding-inline-start: 25px;
      padding-inline-end: 25px;
    }
  }
}

// 퍼플 모바일앱 - 인앱브라우저일 때 처리
html[data-platform="purpleApp"] {
  body.ncPurple {
      // 모바일앱일 때, 태블릿 가로해상도에서 보아도 wrap-header 노출되도록
      .wrap-header {
        @include bp(pc) {
            display: block !important;
        }
      }

      .container {
          padding-top: 50px;
          @include bp(pc) {
            padding-top: 0;
          }
      }

      &.page-main {
        .container {
            padding-top: 0;
        }
      }
  }
}

// 커뮤니티 SDK 웹뷰
// [S] CommunitySDK
body.ncCommunitySDK {
  .container {
    padding-bottom: 32px;
  }

  // [S] profile
  .depth3-list-wrap {
    margin-top: 0;
    margin-bottom: 14px;
    background-color: transparent !important;

    .scroller {
      width: 100% !important;
    }

    // .depth3-list {
    //   width: 100%;
    //   padding: 0;
    //   border-bottom: none;
    // }

    .depth3-list-items {
      height: 31px;
      display: table-cell;
      font-weight: normal;
      box-sizing: border-box;
      background-color: var(--ncui-gray004);
      border-bottom: 1px solid var(--ncui-gray012);
      border-left: 1px solid var(--ncui-gray012);
      border-top: 1px solid var(--ncui-gray012);
      width: 100%;
      font-size: 12px;

      a {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        white-space: normal;
        line-height: unset;
        margin: 0;
        // padding: 0 10px;
        color: var(--ncui-black068);
      }

      &.selected {
        background-color: var(--ncui-bg);
        border-bottom: none;
        border-top: 2px solid var(--ncui-point);

        a {
          color: var(--ncui-point-text);
          border-bottom: none;
          margin-top: -1px;
        }
      }

      &:first-child {
        margin: 0;
      }

      &:last-child {
        border-right: 1px solid var(--ncui-gray012);
      }
    }
  }

  &.page-board-list,
  &.page-board-comment,
  &.page-board-bookmark,
  &.page-my-profile,
  &.page-other-profile-list,
  &.page-other-profile-comment {
    .wrap-contents {
      padding: 0 14px;
    }

    .profile-box {
      padding: 14px 0 13px 0;
      background-color: transparent;
      .pc-wrap {
        flex-direction: column;
      }

      .thumb-wrap {
        display: block;
        margin: 0;
        .thumb {
          width: 40px;
          height: 40px;
        }
      }

      .character {
        margin-top: 2px;
      }
    }

    .board-article-container {
      border-top: 0;

      .board-items {
        padding: 14px 0;
        
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
  // [E] profile

  // [S] sdkmenu  
  &.page-sdkmenu {
    background-color: var(--ncui-gray008-hex);
  }

  .sdk-panel {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    min-height: 100dvh;
    padding: 14px 14px 16px;
    // border-radius: 3px;
    // background-color: var(--ncui-gray008-hex);

    .sdk-shortcut {
      border-radius: 3px;
      background-color: #FFF;
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.03);
      overflow: hidden;

      &-list {
        display: flex;        
        height: 100%;
        margin: 0;
      }

      &-item {
        container: shortcutItem / inline-size;
        position: relative;
        width: 100%;
        min-height: 46px;
        
        &:not(:nth-child(1))::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0px;
          width: 1px;
          height: 10px;
          margin: -5px 0 0;
          background-color: var(--ncui-gray020);
        }

        > a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          color: var(--ncui-black068);
          font-size: 14px;
          @container shortcutItem (width < 110px) { // container로 지정한 .sdk-shortcut-item의 너비가 110px 미만일 때
            flex-direction: column;
            padding: 10px 5px;
            font-size: 13px !important;
            text-align: center;
          }
        }

        .desc {
          word-break: break-all;
          @container shortcutItem (width < 110px) { // container로 지정한 .sdk-shortcut-item의 너비가 110px 미만일 때
            margin-top: 2px;
            @include line-clamp(2);
          }
        }

        .profile-thumb {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          overflow: hidden;
        }
        
        .icon-shortcut {
          flex-shrink: 0;
          margin: 0 4px 0 0;
          background-color: var(--ncui-black093);
          overflow: hidden;
          @container shortcutItem (width < 110px) { // container로 지정한 .sdk-shortcut-item의 너비가 110px 미만일 때
            height: 18px !important;
            margin: 0;
          }

          &--home {
            width: 14px;
            height: 14px;
            mask-size: 14px 14px;
            -webkit-mask-size: 14px 14px;
            @include svg-mask('../../../img/md-sdk/shortcut-symbols.svg#icon_shortcut_home');
          }

          &--coupon {
            width: 16px;
            height: 12px;
            mask-size: 16px 12px;
            -webkit-mask-size: 16px 12px;
            @include svg-mask('../../../img/md-sdk/shortcut-symbols.svg#icon_shortcut_coupon');
          }

          &--cs {
            width: 18px;
            height: 18px;
            margin-right: 2px;
            mask-size: 18px 18px;
            -webkit-mask-size: 18px 18px;
            @include svg-mask('../../../img/md-sdk/shortcut-symbols.svg#icon_shortcut_cs');
          }
        }
      }
    }

    .sdk-lnb {
      &-list {
        margin: 0 0 7px;
      }

      &-item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.65);
        
        &.is-active > a {
          color: var(--ncui-point);
        }

        > a {      
          display: block;    
          width: 100%;
          padding: 11px 0 12px;
          color: var(--ncui-black093);
          font-size: 15px;
        }

        .icon-new {
          color: #ff5f3c;
          font-size: 12px;
          font-style: normal;
        }        
      } 
    }

    //sdk용 풋터
    .sdk-footer {
      .wrap-footer {
        display: block;
        margin-top: 4px;
        background-color: transparent;
      }

      .footer {
        padding: 0;

        &-sns,
        &-logo-wrap {
          display: none;
        }

        &-links,
        &-info,
        &-copyright {
          font-size: 12px;
          line-height: 19px;
          text-align: left;
        }

        &-links {
          margin: 0;
          padding: 0;
          border: 0;
          .footer-links-list{
            margin: 7px 0 -10px;
            .footer-links-item{
              padding-right: 10px;
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        &-info {
          display: block;        
          margin-top: 10px;
          color: #969696;
          padding: 0; 

        }
        &-info-tw{
          .info-inner{
            justify-content: start;
          }
        }
        &-info-jp{
          margin-top: 20px;
        }

        &-copyright {
          margin-top: 5px;
          color: #696969;
          padding: 0;
        }        
      } 
    }
  }  
  // [E] sdkmenu
}

html.dark-mode .ncCommunitySDK {
  &.page-sdkmenu {
    background-color: #2B2B2B;
  }
  .sdk-panel {
    // background-color: #2B2B2B;

    .sdk-shortcut {
      background: var(--ncui-gray004);
    }

    .sdk-lnb {
      &-item {
        border-bottom: 1px solid var(--ncui-gray008);
      }
    }
  }
}
// [E] CommunitySDK



// rwd toggle
[data-rwd-device~="tablet"],
[data-rwd-device*=" tablet"],
[data-rwd-device~="pc"],
[data-rwd-device*=" pc"] {
  display: none;
}

[data-rwd-device~="mobile"],
[data-rwd-device*=" mobile"] {
  display: block;
}

@include bp(tablet) {
  [data-rwd-device~="mobile"],
  [data-rwd-device*=" mobile"] {
    display: none;
  }

  [data-rwd-device~="tablet"],
  [data-rwd-device*=" tablet"] {
    display: block;
  }
}

@include bp(pc) {
  [data-rwd-device~="tablet"],
  [data-rwd-device*=" tablet"] {
    display: none;
  }

  [data-rwd-device~="pc"],
  [data-rwd-device*=" pc"] {
    display: block;
  }
}

.ANDROID {
  [android-only] {
    display: inline-block;
  }

  [ios-only] {
    display: none;
  }
}
.IOS {
  [android-only] {
    display: none;
  }

  [ios-only] {
    display: inline-block;
  }
}
