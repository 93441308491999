// 서비스별 이미지 데이터 설정
$services: (
  "hoyeon": (
    "guild": (
      "light": "/img/official/service/hoyeon/bg_guild_header.png",
      "dark": "/img/official/service/hoyeon/bg_guild_header_dark.png"
    ),
    "profile": (
      "light": "/img/official/service/hoyeon/profile.png"
    )
  ),
  "tl": (
    "guild": (
      "light": "/img/official/service/tl/bg_guild_header.png",
      "dark": "/img/official/service/tl/bg_guild_header_dark.png"
    ),
    "profile": (
      "light": "/img/official/service/tl/profile.png"
    )
  ),
  "lineagew": (
    "guild": (
      "light": "/img/official/service/lineagew/bg_guild_header.png",
      "dark": "/img/official/service/lineagew/bg_guild_header_dark.png"
    ),
    "profile": (
      "light": "/img/official/service/lineagew/profile.png"
    )
  ),
  "bns2": (
    "guild": (
      "light": "/img/official/service/bns2/bg_guild_header.png",
      "dark": "/img/official/service/bns2/bg_guild_header_dark.png"
    ),
    "profile": (
      "light": "/img/official/service/bns2/profile.png"
    )
  ),
  "lineage2m": (
    "guild": (
      "light": "/img/official/service/lineage2m/bg_guild_header.png",
      "dark": "/img/official/service/lineage2m/bg_guild_header_dark.png"
    ),
    "profile": (
      "light": "/img/official/service/lineage2m/profile.png"
    )
  )
);

@each $service, $data in $services {
  $serviceSelector: if($service == "lineage2m", '[data-service^="lineage2m"]', "[data-service='#{$service}']");
  #{$serviceSelector} {
    .my-guild-header {
      background-image: url(map-get(map-get($data, "guild"), "light"));
      @include bp(pc) {
        background: none;
      }
      @include dark-mode {
        background-image: url(map-get(map-get($data, "guild"), "dark"));
        @include bp(pc) {
          background: none;
        }
      }
      .profile {
        background-image: url(map-get(map-get($data, "profile"), "light"));
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center top;
      }
      @if $service == "hoyeon" {
        .info {
          .name:before {
            @include bp(notPC) {
              border-color: rgba(255, 255, 255, 0.4);
            }
          }
        }
      }
    }
  }
}


/*// [S] Lineage2M (공통)
[data-service^="lineage2m"]{
  .my-guild-header{
    background-image: url("/img/official/service/lineage2m/bg_guild_header.png");
    @include bp(pc) {
      background: none;
    }
    .profile{
      background: url("/img/official/service/lineage2m/profile.png") no-repeat center top;
      background-size: auto 100%;
    }
  }
}
html.dark-mode[data-service^="lineage2m"]{
  .my-guild-header {
    background-image: url("/img/official/service/lineage2m/bg_guild_header_dark.png");
    @include bp(pc) {
      background: none;
    }
  }
}
// [E] Lineage2M (공통)*/

// [S] Lineage2M NCJ
[data-service="lineage2mncj"]{

  // noti 영역 숨김 처리
  #nc-cnb .ncc-login--info__noti,
  #nc-cnb .ncc-noti-wrap,
  html.dark-mode #nc-cnb .ncc-login--info__noti,
  html.dark-mode #nc-cnb .ncc-noti-wrap {
    display: none !important
  }

  //일본에서는 페북, 트위터, 라인(모바일에서만), copy만 사용
  .pc .nc-share {
    .btn__share--line,
    .btn__share--kakaotalk {
      display: none;
    }
  }

  .mobile .nc-share {
    .btn__share--kakaotalk {
      display: none;
    }
  }

  //일본어에서 영역 깨짐으로 수정
  .board-top-utils .co-btn-write {
    @include bp(pc) {
      width: 121px;
    }
  }
  .board-list-search .search-info .writer {
    max-width: 40px;
    @include bp(pc) {
      max-width: none;
    }
  }

  .board-top-utils .wrap-search .ui-dropdown.ui-dropdown-elements {
    width: 110px;
  }

  .board-list-default .comment,
  .board-list-thumb .comment {
    width: 58px;
  }

  //cm스토리,미디어,공지,업데이트 list/view에서 조회순 노출 제거 (추후 요청 대비 주석 처리)
  // .nc-community-view .board-view .view-info .hit,
  // .nc-community-list .board-list-default .info .hit,
  // .nc-community-list .board-list-thumb .info .hit,
  // .nc-community-list .board-list-card .info .hit-count {
  //   .page-board-notice &,
  //   .page-board-update &,
  //   .page-board-movie_clip &,
  //   .page-board-cm_story & {
  //     display: none;
  //   }
  // }

  //모든 게시판 list/view에서 조회순 노출 제거
  .board-list-search .search-info .hit,
  .nc-community-view .board-view .view-info .hit,
  .nc-community-list .board-list-default .info .hit,
  .nc-community-list .board-list-thumb .info .hit,
  .nc-community-list .board-list-card .info .hit-count {
    display: none;
  }

  //ncj 요청으로 옵션 추가 - 마이그레이션 불가로 수동으로 모두 글을 옮겨야되어서 요청
  .nc-community-view .view-body:not(.view-body-policy) .notice_area {
    h2 {
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0 16px;
      margin-top: 0;
      border-bottom: 1px solid #e2e2e2;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
    }

    strong {
      color: #eb6715;
    }

    img {
      max-width: 100%;
    }

    .comment_area {
      margin-top: 1em;
      padding: 0.5em;
      background: #5a5a5a;
      color: #fff;

      h2,
      h3 {
        font-size: 20px;
        font-weight: bold;
        border: none;
        padding: 0;
        margin-bottom: 0.25em;
        background: none;
        color: #ebd331;
      }
    }
  }

  .nc-community-view .view-body:not(.view-body-policy) .caution_area {
    margin: 1em 0 0;
    padding: 0.5em;
    background-color: #fac7c1;
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 0.25em;
      padding: 0;
    }
  }

  //ncj 요청으로 옵션 추가 - 공지게시판 뷰에서만 사용
  .page-board-notice .nc-community-view .view-body {
    .chat {
      position: relative;
      background-color: #e1edfe;
      border-radius: .4em;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 1em;
      line-height: 1.8;
      margin-top: 7em !important;
      strong.color {
        color: #EB6715;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -.8em;
        margin-left: 11em;
        border-right: .8em solid transparent;
        border-bottom: .8em solid #e1edfe;
        border-left: .8em solid transparent;
        transform: skew(54deg);
      }
      &::before {
        content: '';
        display: inline-block;
        margin-top: -5em;
        margin-right:1em;
        width: 8em;
        height: 8em;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
      }
      &.carl::before {
        background-image: url('https://ncjapan2.nefficient.jp/ncjapan/bip/data/image/20210426180529_f4032.png');
        border-radius: 10%;
      }
    }

    .chat2 {
      position: relative;
      background-color: #ffeca9;
      border-radius: .4em;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 1em;
      line-height: 1.8;
      margin-top: 7em !important;
      strong.color {
        color: #EB6715;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -.8em;
        margin-left: 11em;
        border-right: .8em solid transparent;
        border-bottom: .8em solid #ffeca9;
        border-left: .8em solid transparent;
        transform: skew(54deg);
      }
      &::before {
        content: '';
        display: inline-block;
        margin-top: -5em;
        margin-right:1em;
        width: 8em;
        height: 8em;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
      }
      &.ponta::before {
        background-image: url('https://ncjapan2.nefficient.jp/ncjapan/bip/data/image/20210426180535_74d25.png');
        border-radius: 10%;
      }
    }
  }
}
// [E] Lineage2M NCJ

// [S] Lineage2M NCT
[data-service="lineage2mnct"]{

  // noti 영역 숨김 처리
  #nc-cnb .ncc-login--info__noti,
  #nc-cnb .ncc-noti-wrap,
  html.dark-mode #nc-cnb .ncc-login--info__noti,
  html.dark-mode #nc-cnb .ncc-noti-wrap {
    display: none !important
  }

  //대만에서는 페북, 라인(모바일에서만), copy만 사용
  .pc .nc-share {
    .btn__share--twitter,
    .btn__share--line,
    .btn__share--kakaotalk {
      display: none;
    }
  }

  .mobile .nc-share {
    .btn__share--twitter,
    .btn__share--kakaotalk {
      display: none;
    }
  }

  //공유 레이어 중앙정렬
  .nc-share .share__wrap .share__list {
    text-align: center;
  }
}
// [E] Lineage2M NCT

