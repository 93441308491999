// Modal animations
@keyframes ncuim-show {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ncuim-hide {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

// Toast Animations
@keyframes ncuim-toast-show-top {
    0% {
        transform: translate3d(0, -100%, 0);
    }

    100% {
        transform: translate3d(0, 16px, 0);
    }
}
@keyframes ncuim-toast-hide-top {
    0% {
        transform: translate3d(0, 16px, 0);
    }

    100% {
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes ncuim-toast-show-center {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes ncuim-toast-hide-center {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes ncuim-toast-show-bottom {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    100% {
        transform: translate3d(0, -16px, 0);
    }
}
@keyframes ncuim-toast-hide-bottom {
    0% {
        transform: translate3d(0, -16px, 0);
    }

    100% {
        transform: translate3d(0, 100%, 0);
    }
}
